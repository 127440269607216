// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/firebase-config';
import i18next from 'i18next';
import { getUserData } from '../database/user/pond';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [refreshAuth, setRefreshAuth] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if(user){
                // if(!user.emailVerified){
                //     navigate('/login')
                //     return
                // }
                let data = await getUserData(user?.uid);
                let objUser = { ...user, ...data }
    
                if (data && data.customerId) {
                    objUser.invoiceCid = data.customerId
                    objUser.customerId = data.customerId
                } else {
                    objUser.invoiceCid = null
                }
                if (data && data.isPremium) {
                    objUser.premiumCust = "true"
                } else if (data && data.isSuperPremium) {
                    objUser.premiumCust = "true"
                } else {
                    objUser.premiumCust = null
                }
                // objUser.isPremium = data?.isPremium ? data?.isPremium : false
    
                if (data && data.role === 2) {
                    objUser.manager_user = "true"
                }
                objUser.pId = user?.uid || null
    
                objUser.fullName = data?.fullName || ''
    
                if (data && data.customerId) {
                    objUser.invoiceCid = data.customerId
                }
                // if (data && data.isPremium) {
                //     objUser.premiumCust = "true"
                // }
                if (data && data.isSuperPremium) {
                    objUser.premiumCust = "true"
                }
                if (data && data.isSuperAdmin) {
                    objUser.superAdmin = "true"
                }
                if (data && data.pId) {
                    objUser.pId = data.pId
                }
                if (data && data.comName) {
                    objUser.comName = data.comName
                }
                if (data && data.role) {
                    objUser.role = data.role
                }
                if (data && data.timezone) {
                    localStorage.setItem("timezone",data.timezone);
                    objUser.timezone = data.timezone
                }
                if (data && data.selectedLanguage) {
                    localStorage.setItem("lang",data.selectedLanguage ? data.selectedLanguage : "en");
                    objUser.lang = data.selectedLanguage ? data.selectedLanguage : "en"
                    i18next.changeLanguage(
                        data.selectedLanguage ? data.selectedLanguage : "en"
                    );
                }
                setCurrentUser(objUser);
            }else{
                setCurrentUser(null);
            }
        });

        return () => unsubscribe();
    }, [refreshAuth]);

    const onRefreshAuth = () => {
        setRefreshAuth(!refreshAuth)
    }
    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, onRefreshAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
