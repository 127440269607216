import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { app, auth, db, functions } from "../../config/firebase-config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Error, Info } from "../../common/CustomToast";
import { FIREBASE_CREATE_USER_FUNCTION, FIREBASE_EMAIL_MAILGUN_FUNCTION, MAIL_TYPE_EMAIL_VERIFICATION } from "../config";

export const singUpUser = async (email, password, data,navigation) => {
  return new Promise(async (resolve, reject) => {
    try {
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential) {
        navigation("/signup");
      }
      // const resEmail = await sendMailgunMail({email: email,type: MAIL_TYPE_EMAIL_VERIFICATION})

      // await emailVerification(userCredential.user);
      const obj = { ...data, uid: userCredential.user.uid };
      await setDoc(doc(db, "Users", `${userCredential.user.uid}`), obj);
      await auth.signOut()
      return resolve(userCredential.user.uid);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addUserFromUserManagement = async (email, password, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const createUserFB = httpsCallable(functions, FIREBASE_CREATE_USER_FUNCTION);
      const result = await createUserFB({email: email, password: password});
      if(result.data.error){
        Error(result.data.message)
        return resolve(result.data);
      }else{
        const obj = { ...data, uid: result.data.uid };
        await setDoc(doc(db, "Users", `${result.data.uid}`), obj);
        return resolve(result.data.uid);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// This func call only when invited link will hit and do signup
export const singUpUserInvite = async (email, password, data, inviteid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // FIXME - common func for setDoc for everywhere
      const obj = { ...data, uid: userCredential.user.uid };
      await setDoc(doc(db, "Users", `${userCredential.user.uid}`), obj);
      await updateDoc(doc(db, "InviteHistory", `${inviteid}`), { isSignup: true, uid: userCredential.user.uid });
      return resolve(userCredential.user.uid);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createUser = async (t, password, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const functions = getFunctions(app, "europe-west1");
      const callableReturnMessage = httpsCallable(functions, "createUser");
      data.password = password;
      callableReturnMessage({ data })
        .then(async (result) => {
          var dataResult = result.data;
          if (dataResult) {
            if (dataResult.error) {
              return resolve({ error: dataResult.error });
            } else if (dataResult.uid) {
              const obj = { ...data, uid: dataResult.uid };
              await setDoc(doc(db, "Users", `${dataResult.uid}`), obj);
              return resolve({ uid: dataResult.uid });
            } else {
              return resolve({ error: t("something_went_wrong") });
            }
          } else {
            return resolve({ error: t("something_went_wrong") });
          }
        })
        .catch((error) => {
          return resolve({ error: error.message });
        });
    } catch (error) {
      return resolve({ error: error.message });
    }
  });
};

export const emailVerification = (userCredential) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = userCredential;
      // Send verification email
      await sendEmailVerification(user);
      Info("Registration successful! Please check your email to verify your account.");
      return resolve(true);
    } catch (error) {
      console.log('error: ', error);
      if (error.code === "auth/too-many-requests") {
        // Handle too many requests error specifically
        Error("Too many requests. Please try again later.");
      } else {
        // Handle other types of Firebase auth errors
        Error(error.message || "An error occurred during sign up.");
      }
      return reject(error);
    }
  });
}

export const sendMailgunMail = (data,setResendVerificationLoading = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sendmailgunmailFB = httpsCallable(functions, FIREBASE_EMAIL_MAILGUN_FUNCTION);
      const result = await sendmailgunmailFB(data);
      if(result.data.error){
        Error(result.data.message)
        return resolve(result.data);
      }else{
        Info(result.data.message)
        return resolve(result.data);
      }

    } catch (error) {
      if (setResendVerificationLoading) {
        setResendVerificationLoading(false);
      }
      console.log('error: ', error);
      if (error.code === "auth/too-many-requests") {
        // Handle too many requests error specifically
        Error("Too many requests. Please try again later.");
      } else {
        // Handle other types of Firebase auth errors
        Error(error.message || "An error occurred during sign up.");
      }
      return reject(error);
    }
  });
}