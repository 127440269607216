import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { getLicensesById, updateLicenseToDB } from "./licensing";
import { SLA } from "../../common/configs";

export const getDevices = (pId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, "devices"), where("pId", "==", pId));
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      return resolve(data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevicePool = (pId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, "devicePool"), where("pId", "==", pId));
      const querySnapshot = await getDocs(q);
      let data = [];
      const lstPromise = [];

      querySnapshot.forEach(val => {
        const slaLicId = val.data()?.slaLicId;
        const deviceLicId = val.data()?.deviceLicId;
        let objDevicePool = { id: val.id, ...val.data() };

        if (slaLicId) {
          lstPromise.push(
            getLicensesById(slaLicId).then(res => {
              objDevicePool.slaLic = res;
            })
          );
        }

        if (deviceLicId) {
          lstPromise.push(
            getLicensesById(deviceLicId).then(res => {
              objDevicePool.deviceLic = res;
            })
          );
        }

        data.push(objDevicePool);
      });

      Promise.all(lstPromise).then(() => {
        resolve(data);
      });
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLicensesListInDeviceList = (pId, deviceType, licenseType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, "licenses"), where("pId", "==", pId), where("deviceType", "==", deviceType), where("deviceId", "==", ''), where("type", "==", licenseType));
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      return resolve(data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevicePoolForSwap = (pId, deviceType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, "devicePool"), where("pId", "==", pId), where("type", "==", deviceType), where("isBackUpDevice", '==', true));
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      const lstTmp = data.filter((val) => !val.slaLicId)
      return resolve(lstTmp);
    } catch (error) {
      return reject(error);
    }
  });
};


export const addDevices = (pId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = await addDoc(collection(db, "devices"), data);
      const countRef = doc(db, "counters", `${pId}`);
      await updateDoc(countRef, {
        devices: increment(1),
      });
      return resolve(docRef.id);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateDevices = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "devices", id);
      await updateDoc(docRef, data);
      return resolve("Successfully updated cycle.");
    } catch (error) {
      return reject(error);
    }
  });
};


export const updateDevicePool = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "devicePool", id);
      await updateDoc(docRef, data);
      return resolve("Successfully updated cycle.");
    } catch (error) {
      return reject(error);
    }
  });
};


export const deleteDevices = (pId, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        await deleteDoc(doc(db, `devices`, id));
        const countRef = doc(db, "counters", `${pId}`);
        await updateDoc(countRef, {
          devices: increment(-1),
        });
        return resolve("Deleted Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};


export const getDevicesByPid = (deviceType, licenseType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, "devicePool"), where("type", "==", deviceType), where("isBackUpDevice", "==", false));
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        if (val.data()) {
          data.push({ id: val.id, ...val.data() });
        }
      });
      const lstFinal = data.length ? data.filter((obj) => {
        const LicKey = licenseType === SLA ? 'slaLicId' : 'deviceLicId'
        if (!obj[LicKey])
          return obj
      }) : []
      return resolve(lstFinal);
    } catch (error) {
      return reject(error);
    }
  });
};


export const swapLicense = (oldDevice, newDevice) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resNew = updateDevicePool(newDevice.id, { slaLicId: oldDevice.slaLicId, deviceLicId: oldDevice.deviceLicId, swappedWithDeviceId: oldDevice.id, swappedOn: new Date() })
      const resOld = updateDevicePool(oldDevice.id, { slaLicId: deleteField(), deviceLicId: deleteField(), swappedWithDeviceId: newDevice.id, swappedOn: new Date() })
      const resSlaLic = updateLicenseToDB({ id: oldDevice.slaLicId, deviceId: newDevice.id })
      const resDeviceLic = updateLicenseToDB({ id: oldDevice.deviceLicId, deviceId: newDevice.id })
      return resolve(resDeviceLic);
    } catch (error) {
      return reject(error);
    }
  });
};