import { Navigate, useRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import SignUp from './screens/auth/SignUp';
import Login from "./screens/auth/Login";
import SelectFarm from "./screens/user/pond/SelectFarm";
import Ponds from "./screens/user/pond/Ponds";
import PondDetail from "./screens/user/pond/PondDetail";
import ManageCycle from "./screens/user/pond/ManageCycle";
import PondRecords from "./screens/user/pond/PondRecords";
import Map from "./screens/user/map/Map";
import Alarm from "./screens/user/Alarm";
import Report from "./screens/user/Report";
import Setting from "./screens/user/Setting";
import UserManagement from "./screens/user/userManagement/UserManagement";
import Profile from "./screens/user/Profile";
import RecordImages from "./screens/user/pond/RecordImages";
import BiomassRecords from "./screens/user/pond/BiomassRecords";
import ParametersRecords from "./screens/user/pond/ParametersRecords";
import Devices from "./screens/user/device/Devices";
import DevicesPool from "./screens/user/device/DevicesPool";
import Invoice from "./screens/user/Invoice"
import ViewLinkRecord from "./screens/link/ViewLinkRecord";
import ReportAll from "./screens/user/ReportAll";
import Page404 from './components/Page404';
import Home from "./screens/user/Home";
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import SuperAdminProtectedRoute from './SuperAdminProtectedRoute';
import Users from './screens/user/device/Users';
import MeasurementReports from './screens/user/pond/MeasurementReports';
import CountingReports from './screens/user/pond/CountingReports';
import MeasurementReportsDetail from './screens/user/pond/MeasurementReportsDetail';
import LicenseDetails from './screens/user/pond/LicenseDetails';
import CountingReport from './screens/user/CountingReport';
import ForgotPassword from './screens/auth/ForgotPassword';
import InviteSignUp from './screens/auth/InviteSignUp';
import InviteHistory from './screens/user/device/InviteHistory';
import HomeRoute from './HomeRoute';
import AuthProtectedRoute from './AuthProtectedRoute';
import { auth } from './config/firebase-config';

export default function Router() {
    // auth.signOut()
    const routes = useRoutes([
        {
            path: '/',
            children: [
                { path: '/', element: <HomeRoute /> },
                { path: '/login', element: <AuthProtectedRoute><Login /></AuthProtectedRoute> },
                { path: '/signup', element: <AuthProtectedRoute><SignUp /> </AuthProtectedRoute> },
                { path: '/inviteSignUp/:inviteId', element: <InviteSignUp /> },
                { path: '/viewimages/:recordId', element: <ViewLinkRecord /> },
                { path: '/forgot_password', element: <ForgotPassword /> },
            ]
        },

        // supper admin routes
        {
            path: '/superadmin',
            element: <SuperAdminProtectedRoute><DashboardLayout /></SuperAdminProtectedRoute>,
            children: [
                { path: '/superadmin/devices_pool', element: <DevicesPool /> },
                { path: '/superadmin/users', element: <Users /> },
                { path: '/superadmin/license_details', element: <LicenseDetails /> },
                { path: '/superadmin/invite_history', element: < InviteHistory /> },
            ],
        },

        // admin routes
        {
            path: '/',
            element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
            children: [
                //  { path: '/', element: <WidgetRedirect /> },
                { path: '/home', element: <Home /> },
                { path: '/selectFarm', element: <SelectFarm /> },
                { path: '/Ponds', element: <Ponds /> },
                { path: '/pond_detail', element: <PondDetail /> },
                { path: '/pondCycle', element: <ManageCycle /> },
                { path: '/pond_record', element: <PondRecords /> },
                // { path: '/image_record', element: <RecordImages /> },
                { path: '/biomass_record', element: <BiomassRecords /> },
                { path: '/parameters_records', element: <ParametersRecords /> },
                // { path: '/map', element: <Map /> },
                // { path: '/alarm', element: <Alarm /> },
                { path: '/report', element: <Report /> },
                { path: '/management', element: <UserManagement /> },
                { path: '/device', element: <Devices /> },
                { path: '/invoice', element: <Invoice /> },
                // { path: '/profile', element: <Profile /> },
                { path: '/setting', element: <Setting /> },
                { path: '/counting_report', element: <CountingReport /> },
                { path: '/report_all', element: <ReportAll /> },
                { path: '/measurementReports', element: <MeasurementReports /> },
                { path: '/countingReports', element: <CountingReports /> },
                { path: '/MeasurementReportsDetail', element: <MeasurementReportsDetail /> },
            ],
        },

        {
            path: '/',
            children: [
                { path: '/404', element: <Page404 /> },
            ],
        },

        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
