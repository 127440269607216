import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInvoiceList } from "../../api/api";
import CustomSegments from "../../components/CustomSegments";
import Invoice from "../user/Invoice";
import Subscriptions from "./Subscriptions";
import { useAuth } from "../../common/AuthContext";

function Billing(props) {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const segmentList = [
    { id: 1, title: t("settings.Subscription_plans") },
    { id: 2, title: t("settings.Billing_history") },
  ]
  const [selectedSegment, setSelectedSegment] = useState(1);

  useEffect(() => {
    async function getDatas() {
      try {
        const data = await getInvoiceList(currentUser.invoiceCid);
        if (data && data.length > 0) {
          data.filter((ele) => {
            if (ele.status === "paid") {
              setCurrentUser({ ...currentUser, stickyDays: null })
            }
            return;
          });
        }
      } catch (error) {
        console.log('Get InvoiceList error: ', error);
      }
    }
    getDatas();
  }, []);

  return (
    <div className="inner_dropdown_block">
      <CustomSegments
        segmentList={segmentList}
        selectedSegment={selectedSegment}
        onClickSelectedSegment={(ind) => {
          setSelectedSegment(ind);
        }}
      />
      <div
        sx={{ padding: 2, boxShadow: "0.5px 1px 1px 2px #e0e0e0" }}
        className="setting_max_block"
      >
        {selectedSegment === 1 && (
          <Subscriptions profile={currentUser} selected={props.selected} objCloud={props.objCloud} />
        )}
        {selectedSegment === 2 && <Invoice profile={currentUser} />}
      </div>
    </div>
  );
}

export default Billing;
