import { Delete } from "@mui/icons-material";
import { Box, Card, Typography, Divider, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import {
  addBiomassRecord,
  deleteBiomassRecord,
  getBiomassRecordAllCycle,
  getUserData,
  updatePondCycleById,
} from "../../../database/user/pond";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import { getCurrentTimestampInMillis, getTimestampWithFormatDate } from "../../../common/utils";
import { useAuth } from "../../../common/AuthContext";

function BiomassRecords() {
  const navigation = useNavigate();
  const {currentUser} = useAuth();
  const { t } = useTranslation();
  const [recordList, setRecordList] = useState([]);
  const [user, setUser] = useState(null);
  const [isData, setIsData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [noOfBiomass, setNoOfBiomass] = useState(
    window.history.state.usr.cycleData
      ? window.history.state.usr.cycleData.biomass
        ? window.history.state.usr.cycleData.biomass
        : 0
      : 0
  );
  const [noOfBiomass2, setNoOfBiomass2] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  // useEffect(() => {
  //   async function handleEscapeKey(event) {
  //     if (event.code === "Enter") {
  //       await onClickUpdateBiomass();
  //     }
  //   }
  //   document.addEventListener("keydown", handleEscapeKey);
  //   return () => document.removeEventListener("keydown", handleEscapeKey);
  // }, []);

  useEffect(() => {
    getBiomassRecords();
  }, []);
  const getBiomassRecords = async () => {
    try {

      let data = await getBiomassRecordAllCycle(
        window.history.state.usr.cycleData
          ? window.history.state.usr.cycleData.id
          : null
      );
      data.sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      });
      let uid = currentUser?.uid
      let userData = await getUserData(uid);
      setUser(userData);
      if (data.length > 0) {
        setRecordList(data);
      } else {
        setIsData(true);
      }
    } catch (error) {

    }
  };

  const onClickUpdateBiomass = async () => {
    setIsDisable(true);
    try {
      if (isDisable) {
      } else {
        if (document.getElementById("biomass2").value === "") {
          setIsDisable(false);
          alert(t("ponds.please_fill_no_of_biomass"));
        } else {
          setIsOpenModal(false);
          setIsUpdateLoader(true);
          if (
            window.history.state.usr.cycleData &&
            window.history.state.usr.cycleData.id
          ) {
            const num = document.getElementById("biomass2").value;

            let data = {
              biomass: Number(num),
              timestamp: getCurrentTimestampInMillis(),
            };
            if (user) {
              data = {
                ...data,
                userId: user.uid,
                userName: user.fullName,
                isFromDevice: false,
              };
            }
            const addId = await addBiomassRecord(
              currentUser.pId,
              window.history.state.usr.cycleData.id,
              num,
              data
            );
            await updatePondCycleById(window.history.state.usr.cycleData.id, {
              biomass: noOfBiomass + Number(num),
            });
            // const docRef = doc(db, "counters", "appCounters");

            // await updateDoc(docRef, {
            //   biomass: increment(Number(num)),
            // });
            let list = recordList;
            list.unshift({ ...data, id: addId });
            setRecordList(list);
            setNoOfBiomass(noOfBiomass + Number(num));
          } else {
            alert(t("ponds.no_active_cycle_found_in_this_pond"));
          }
          setNoOfBiomass2("");
          setIsUpdateLoader(false);
          setIsOpenModal(false);
          setIsDisable(false);
        }
      }
    } catch (error) {

      setIsDisable(false);
    }
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      //   headText={`${t("ponds.record_for_pond")}: ${
      //     window.history.state.usr.cardData.pondName
      //   }`}
      headText={t("ponds.biomass_records")}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: window.history.state.usr.cardData,
            cycleData: window.history.state.usr.cycleData,
          },
        });
      }}
    >
      {/* ----- HEADER ----- */}
      <div className="head_container" style={{ marginTop: -20 }}>
        <div className="head_one"></div>
        <div className="head_two" style={{ marginLeft: -2 }}>
          <button
            className="ui primary button"
            style={{ width: 120, marginLeft: 5 }}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            {t("ponds.add")}
          </button>
        </div>
      </div>
      {/* ----- CARD ----- */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            alignItems: "center",
            padding: 2,
            boxShadow: "1px 2px 2px 2px #e0e0e0",
            borderRadius: 5,
          }}
          className="card-css-block"
        >
          <Typography
            sx={{ fontSize: 50, fontWeight: "600", color: colors.mid_blue }}
          >
            {noOfBiomass}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: "500", marginTop: -0.5 }}>
            {t("ponds.total_biomass_in_current_cycle")}
          </Typography>
        </Card>
      </div>

      {/* ----- TABLE ----- */}
      {recordList.length > 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            overflow: "auto",
            marginTop: 30,
          }}
        >
          <table
            className="ui celled table user-manage-tab"
            style={{ marginTop: 0 }}
          >
            <thead>
              <tr>
                <th>{t("home.time")}</th>
                <th>{t("ponds.biomass")}</th>
                <th>{t("ponds.user")}</th>
                <th>{t("ponds.id")}</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {recordList.map((val, index) => {
                return (
                  <tr
                    // style={{ backgroundColor: val.color }}
                    key={Math.random()}
                  >
                    <td
                      data-label={t("home.time")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {getTimestampWithFormatDate(val.timestamp,"DD-MM-YYYY @ hh:mma")}
                    </td>
                    <td
                      data-label={t("ponds.biomass")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.biomass}
                    </td>
                    <td
                      data-label={t("ponds.user")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.userName}
                    </td>
                    <td
                      data-label={t("ponds.user")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.id}
                    </td>
                    <td>
                      <div
                        style={style.container}
                        className="farm-svg-css delete_icon_td"
                      >
                        <div className="look_icon_css red_icon_eye">
                          <Delete
                            sx={{ marginLeft: 2 }}
                            onClick={() => {
                              setDeleteData({
                                index: index,
                                ...val,
                              });
                              setDeleteModal(true);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : isData ? (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
            {t("ponds.biomass_not_found_msg")}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )}

      {isUpdateLoader && (
        <div
          style={{
            backgroundColor: "black",
            opacity: 0.5,
            position: "absolute",
            zIndex: 10,
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        ></div>
      )}
      {isUpdateLoader && (
        <div
          style={{
            position: "absolute",
            zIndex: 55,
            top: 45,
            left: 90,
            display: "flex",
            height: "80vh",
            // height: '100%',
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )}

      {/* ------ ADD MODAL ------ */}
      <Modal
        open={isOpenModal}
        onClose={() => {
          if (isDisable) {
          } else {
            setNoOfBiomass2("");
            setIsOpenModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form>
          <Box sx={boxStyle} className="logout-css-modal-block">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ paddingTop: 2, textAlign: "center", fontWeight: "600" }}
            >
              {t("ponds.add") + " " + t("ponds.biomass")}
            </Typography>
            <div
              className="ui input"
              style={{
                width: "100%",
                marginTop: 15,
                marginBottom: 30,
              }}
            >
              <input
                onChange={(e) => setNoOfBiomass2(e.target.value)}
                value={noOfBiomass2}
                id="biomass2"
                type="number"
                placeholder={t("please_enter_biomass")}
                required
              />
            </div>

            <Divider sx={{ backgroundColor: "#999" }} />
            <div className="logout-button-block submit-logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setNoOfBiomass2("");
                    setIsOpenModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <span></span>
              <button
                type="submit"
                autoFocus
                onClick={async () => {
                  await onClickUpdateBiomass();
                }}
              >
                {t("ponds.add")}
              </button>
            </div>
          </Box>
        </form>
      </Modal>

      {/* ----- DELETE MODAL ----- */}
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("ponds.are_you_sure_you_want_to_delete_this")}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setDeleteModal(false);
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              className="delete_bg_color"
              sx={[textStyle, { color: "red" }]}
              onClick={async () => {
                if (isDisable) {
                } else {
                  try {
                    const num = noOfBiomass - deleteData.biomass;
                    const data = await deleteBiomassRecord(
                      currentUser.pId,
                      window.history.state.usr.cycleData.id,
                      deleteData,
                      num
                    );
                    if (data) {
                      let list = recordList.filter((ele) => {
                        return ele.id !== deleteData.id;
                      });
                      setRecordList(list);
                    }
                    setNoOfBiomass(
                      deleteData.biomass > 0
                        ? noOfBiomass - deleteData.biomass
                        : noOfBiomass
                    );
                    setIsDisable(false);
                    setDeleteModal(false);
                    // setDeleteModal(false);
                  } catch (error) {

                    setIsDisable(false);
                  }
                }
              }}
            >
              {t("ponds.delete")}
            </Typography>
          </div>
        </Box>
      </Modal>
    </ResponsiveDrawer>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 375 ? 400 : 270,
  // width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default BiomassRecords;
