import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInvoiceList } from "../../api/api";
import colors from "../../common/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { getDateWithMillisecondFormat } from "../../common/utils";
import { useAuth } from "../../common/AuthContext";

function Invoice(props) {
  const { profile } = props;
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const [invoiceList, setInvoiceList] = useState([]);
  const [isData, setIsData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedIndexData, setSelectedIndexData] = useState(null);

  useEffect(() => {
    async function getDatas() {
      try {
        const data = await getInvoiceList(currentUser.invoiceCid);
        if (data && data.length > 0) {
          data.filter((ele) => {
            if (ele.status === "paid") {
              setCurrentUser({ ...currentUser, stickyDays: null })
            }
            return;
          });
          setInvoiceList(data);
        } else {
          setIsData(true);
        }

      } catch (error) {

      }
    }
    getDatas();

  }, []);

  return (
    <div className="inventory_table_wrap setting_invoice_table_block">
      <div className="table-responsive cm_table_responsive">
        {invoiceList.length > 0 ? (
          <table
            className="ui celled table cm_table fold-table"
            style={{ marginTop: 0 }}
          >
            <thead className="cm_thead">
              <tr
                style={{ textAlign: "center", verticalAlign: "middle" }}
                className="cm_thead_list"
              >
                <th className="th_list cm_name">
                  <div className="th_item_wrap">
                    <h6>{t("invoice.invoice_number")}</h6>
                  </div>
                </th>
                <th className="th_list cm_name">
                  <div className="th_item_wrap">
                    <h6>{t("invoice.invoice_date")}</h6>
                  </div>
                </th>
                <th className="th_list cm_name">
                  <div className="th_item_wrap">
                    <h6>{t("invoice.amount")}</h6>
                  </div>
                </th>
                <th className="th_list cm_name">
                  <div className="th_item_wrap">
                    <h6>{t("invoice.created")}</h6>
                  </div>
                </th>
                <th className="th_list cm_name">
                  <div className="th_item_wrap">
                    <h6>{t("invoice.status")}</h6>
                  </div>
                </th>
                <th className="th_list cm_name"></th>
                <th className="th_list cm_name"></th>
              </tr>
            </thead>
            <tbody className="cm_tbody">
              {invoiceList.map((val, index) => {
                if (val.status === "open" || val.status === "paid") {
                  return (
                    <React.Fragment key={Math.random()}>
                      <tr
                        className={`cm_tr view ${selectedIndex === index ? "cm_tr_open_block" : ""
                          }`}
                      >
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          {val.number}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          {getDateWithMillisecondFormat(val.created, "MMM DD, YYYY")}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          €{Number(val.total) / 100}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          {getDateWithMillisecondFormat(val.created, "MMM DD, YYYY")}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          {val.status === "paid" ? (
                            <Typography
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "600",
                                color: "black",
                              }}
                            >
                              {val.status}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "600",
                                color: colors.lightRed,
                              }}
                            >
                              {t("invoice.unpaid")}
                            </Typography>
                          )}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                        >
                          {/* {val.status === "open" && ( */}
                          <button
                            className="ui primary button"
                            onClick={() => {
                              window.open(val.hosted_invoice_url, "_blank");
                            }}
                          >
                            {val.status === "paid" ? "View Receipt" : "Pay"}
                          </button>
                          {/* )} */}
                        </td>
                        <td
                          className="cm_td"
                          style={{ verticalAlign: "middle" }}
                          onClick={() => {
                            setSelectedIndex(
                              selectedIndex === index ? -1 : index
                            );
                            if (
                              val.lines &&
                              val.lines.data &&
                              val.lines.data.length > 0
                            ) {
                              setSelectedIndexData(val.lines.data);
                            } else if (selectedIndex === index) {
                              setSelectedIndexData(null);
                            }
                          }}
                        >
                          <div className="open_toggle_svg_css">
                            {selectedIndex === index ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowUp />
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr className="cm_hi"></tr>
                      {selectedIndex === index && (
                        <tr
                          style={{
                            height: "auto",
                            whiteSpace: "nowrap",
                            width: "100%",
                          }}
                          className="open_tr_block"
                        >
                          <td colSpan={7}>
                            <table
                              className="ui table"
                              style={{
                                margin: "auto",
                                marginTop: 15,
                                marginBottom: 15,
                                width: "93%",
                              }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <th
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {t("invoice.item")}
                                  </th>
                                  <th
                                    style={{
                                      borderLeft: "none",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {t("invoice.qty")}
                                  </th>
                                  <th
                                    style={{
                                      borderLeft: "none",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {t("invoice.amount")}
                                  </th>
                                  <th
                                    style={{
                                      borderLeft: "none",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {t("invoice.total")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedIndexData.map((ele) => {
                                  return (
                                    <tr key={Math.random.apply().toString()}>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {ele.description ? ele.description : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          borderBottom: "none",
                                          borderLeft: "none",
                                        }}
                                      >
                                        {ele.quantity ? ele.quantity : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          borderBottom: "none",
                                          borderLeft: "none",
                                        }}
                                      >
                                        €
                                        {ele.price.unit_amount
                                          ? Number(ele.price.unit_amount) / 100
                                          : 0}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          fontWeight: "600",
                                          fontSize: 15,
                                          borderBottom: "none",
                                          borderLeft: "none",
                                        }}
                                      >
                                        €
                                        {ele.amount
                                          ? Number(ele.amount) / 100
                                          : 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            <div
                              style={{
                                display: "flex",
                                margin: "auto",
                                width: "85%",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography sx={{ fontSize: 17 }}>
                                {t("invoice.total_amount")}:&nbsp;&nbsp;
                                <span
                                  style={{ fontWeight: "600", fontSize: 16 }}
                                >
                                  €{val.total ? Number(val.total) / 100 : 0}
                                </span>
                              </Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                }
              })}
            </tbody>
          </table>
        ) : isData ? (
          <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
              {profile.isSuperPremium ? t("superPremiumMessage") : t("invoice.no_invoices_found")}
            </Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        )}
      </div>
    </div>
    // </ResponsiveDrawer>
  );
}

export default Invoice;
