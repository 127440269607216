import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentSubscription } from "../../api/api";
import colors from "../../common/colors";
import { Card } from "@mui/material";
import { CUSTOMER_PORTAL_URL, PRICING_TABLE_ID, PUBLISHABLE_KEY } from "../../common/configs";
import { getDateWithMillisecondFormat } from "../../common/utils";
import { useAuth } from "../../common/AuthContext";

function Subscriptions(props) {
  const { objCloud } = props
  const { t } = useTranslation();
  const {currentUser} = useAuth();
  const [isData, setIsData] = useState(false)
  const [profileData, setProfileData] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isUpgradeClick, setUpgradeClick] = useState((props && props.selected) ? true : false);
  const [featureList, setFeatureList] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSuperPremium, setSuperPremium] = useState(false);
  const [endsAt, setEndAt] = useState(0);
  useEffect(() => {
    async function getDatas() {
      try {
        const profile = {...currentUser}
        if (profile.isSuperPremium) {
          setSuperPremium(true)
        }
        setProfileData(profile);
        if (profile.customerId) {
          const currentSubscription = await getCurrentSubscription(profile.customerId);
          if (currentSubscription) {
            setCurrentSubscription(currentSubscription)
            if (currentSubscription.cancel_at_period_end) {
              setIsCanceled(currentSubscription.cancel_at_period_end)
            }
            if (currentSubscription.cancel_at) {
              setEndAt(currentSubscription.cancel_at)
            }
          }
          if (currentSubscription && currentSubscription.lines && currentSubscription.lines.data[0].price.nickname) {
            setFeatureList(currentSubscription.lines.data[0].price.nickname.split(","))
          }
        }
        setIsData(true)
      } catch (error) {
      }
    }
    getDatas();
  }, []);

  return (
    (isData && (!profileData || !profileData.customerId || !currentSubscription)) ? isUpgradeClick ? (
      <stripe-pricing-table
        pricing-table-id={PRICING_TABLE_ID}
        publishable-key={PUBLISHABLE_KEY}
        customer-email={currentUser?.email}>
      </stripe-pricing-table>
    ) : (
      objCloud ?
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Card
            sx={{
              width: "20%",
              alignSelf: 'center',
              boxShadow: "1px 2px 2px 2px #e0e0e0",
              borderRadius: 5,
              border: `2px solid ${colors.blue}`,
            }}>
            <div
              style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div
                style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: colors.blue,
                    textAlign: 'center',
                    fontSize: 20
                  }}
                >
                  {t("currently_active")}
                </Typography>
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: colors.black,
                    alignSelf: 'center',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 28
                  }}
                >
                  {objCloud.tier}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {`- ${objCloud.planUsers} ${t("home.users")}`}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {`- ${objCloud.plansRecords} ${t("home.records")}`}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {t("invoice.freeFeature3")}
                </Typography>
              </div>
            </div>
          </Card>
        </div>
        :
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Card
            sx={{
              width: "20%",
              alignSelf: 'center',
              boxShadow: "1px 2px 2px 2px #e0e0e0",
              borderRadius: 5,
              border: `2px solid ${colors.blue}`,
            }}>
            <div
              style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div
                style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: colors.blue,
                    textAlign: 'center',
                    fontSize: 20
                  }}
                >
                  {t("currently_active")}
                </Typography>
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: colors.black,
                    alignSelf: 'center',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 28
                  }}
                >
                  {isSuperPremium ? t("enterprise") : t("FREE")}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {isSuperPremium ? `- 50 ${t("home.users")}` : t("invoice.freeFeature1")}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {isSuperPremium ? `- 50000 ${t("home.users")}` : t("invoice.freeFeature2")}
                </Typography>
                <Typography
                  style={{
                    color: colors.black,
                    width: '100%',
                    fontSize: 16,
                    marginLeft: 20
                  }}
                >
                  {t("invoice.freeFeature3")}
                </Typography>

                {!isSuperPremium && (<div className="column base_container" style={{ marginTop: 10, }}>
                  <button
                    className="ui primary button"
                    style={{ width: "auto", height: 40 }}
                    onClick={async () => {
                      setUpgradeClick(true)
                    }}
                  >
                    {t("invoice.upgrade_to_premium")}
                  </button>
                </div>)}
              </div>

            </div>
          </Card>
        </div>) : ((isData) ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Card
              sx={{
                width: "20%",
                alignSelf: 'center',
                boxShadow: "1px 2px 2px 2px #e0e0e0",
                borderRadius: 5,
                border: `2px solid ${colors.blue}`,
              }}>
              <div
                style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      color: colors.blue,
                      textAlign: 'center',
                      fontSize: 20
                    }}
                  >
                    {t("currently_active")}
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: colors.black,
                      alignSelf: 'center',
                      textAlign: 'center',
                      marginTop: 10,
                      fontSize: 28
                    }}
                  >
                    {currentSubscription && currentSubscription.lines.data[0] > 0 ? currentSubscription.lines.data[0].description.toString().split("×")[1].split("(")[0] : ''}
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: colors.black,
                      alignSelf: 'center',
                      textAlign: 'center',
                      marginTop: 10,
                      fontSize: 28
                    }}
                  >
                    €{currentSubscription.lines.data[0].amount / 100}
                  </Typography>
                  <Typography
                    style={{
                      color: colors.black,
                      alignSelf: 'center',
                      textAlign: 'center',
                      fontSize: 18,
                      marginBottom: 15
                    }}
                  >
                    per {currentSubscription.lines.data[0].plan.interval}
                  </Typography>
                  {featureList.map((val, index) => {
                    return (<Typography
                      style={{
                        color: colors.black,
                        width: '100%',
                        fontSize: 16,
                        marginLeft: 20
                      }}
                      key={index}
                    >
                      - {val}
                    </Typography>)
                  })}
                  {isCanceled && (<Typography
                    style={{
                      color: colors.red,
                      width: '100%',
                      fontSize: 14,
                      marginTop: 20,
                      textAlign: 'center'
                    }}
                  >
                    {t("invoice.canceled_message")}
                  </Typography>)}
                  {isCanceled && (<Typography
                    style={{
                      color: colors.black,
                      width: '100%',
                      fontSize: 18,
                      marginTop: 20,
                      textAlign: 'center'
                    }}
                  >
                    {t("invoice.ends_at") + getDateWithMillisecondFormat(endsAt, "MMM DD, YYYY")}
                  </Typography>)}
                  <div className="column base_container" style={{ marginTop: 30, }}>
                    <button
                      className="ui primary button"
                      style={{ width: "auto", height: 40 }}
                      onClick={async () => {
                        window.open(CUSTOMER_PORTAL_URL);
                      }}
                    >
                      {isCanceled ? t("invoice.renewSubscription") : t("invoice.manage_subscription")}
                    </button>
                  </div>
                </div>

              </div>
            </Card>
          </div>) : <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
          <div id={`load`} className="loader" />
        </div>)
  );
}

export default Subscriptions;
