import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db,auth } from "./config/firebase-config";
import { onSnapshot,doc } from "firebase/firestore";
import { getRoleByUid } from "./database/auth/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Error } from "./common/CustomToast";
import i18next from "i18next";

const ProtectedRoute = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    let unsubscribeSnapshot;
    async function checkLock(userLocal){
        if(userLocal && userLocal.uid){
            unsubscribeSnapshot = onSnapshot(
                doc(db, "Users", userLocal.uid.toString()),
                async(doc) => {
                  const data = doc.data();
                  if (data) {
                    if (data?.role === 3) {
                        setIsLoggedIn(false);
                        return;
                    }
                    if (data["isLock"] !== undefined && data["isLock"]) {
                        Error(t("lockMessage"));
                        unsubscribeUser()
                        logout()
                    } else if (data["isDelete"] !== undefined && data["isDelete"]) {
                        Error(t("deleteMessage"));
                        unsubscribeUser()
                        logout()
                    } else {
                        const userRole = await getRoleByUid(userLocal.uid.toString())
                        if (userRole === "admin"|| userRole === "manager") {
                            if (userRole === "manager" && window.location.pathname === "/device") {
                                setIsLoggedIn(false)
                                navigate('/404');
                            } else {
                                setIsLoggedIn(true)
                            }
                        } else {
                            // navigate('/404');
                        }
                    }
                  }
                }
              );
        }
    }

    function unsubscribeUser(){
        if (unsubscribeSnapshot && typeof unsubscribeSnapshot === 'function') {
            unsubscribeSnapshot()
        }
    }

    async function logout(){
        try {
            await signOut(auth);
            i18next.changeLanguage("en");
            localStorage.clear();
            navigate("/login");
          } catch (error) {
            console.log(error);
          }
    }

    const onLoad = useCallback(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user && user.uid) {
                checkLock(user)
            } else {
                unsubscribeUser()
                setIsLoggedIn(false)
                navigate('/login');
            }
        })
    }, [navigate])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : <div className="four_zero_four_image_container" style={{ marginTop: 15 }}>
                    <div id={`load`} className="loader" />
                </div>
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;