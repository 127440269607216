import { Delete } from "@mui/icons-material";
import { Box, Card, Typography, Divider, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import {
  deleteWaterRecord,
  getLastDaysData,
  getUserData,
  getWaterParametersAllCycle,
  getWaterQuality
} from "../../../database/user/pond";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import { addWaterParamRecord } from "../../../database/user/pond";
import LineChart from "../../../components/LineChart";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import {  getCurrentTimestampInMillis, getTimestampWithFormatDate } from "../../../common/utils";
import { useAuth } from "../../../common/AuthContext";

function ParametersRecords() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const {currentUser} = useAuth();
  const [recordList, setRecordList] = useState([]);
  const [user, setUser] = useState(null);
  const [isData, setIsData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [noOfBiomass, setNoOfBiomass] = useState(
    window.history && window.history.state && window.history.state.usr && window.history.state.usr.cycleData
      ? window.history.state.usr.cycleData.biomass
        ? window.history.state.usr.cycleData.biomass
        : 0
      : 0
  );
  const [isDisable, setIsDisable] = useState(false);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [addInput, setAddInput] = useState({
    ph: "",
    salinity: "",
    dissolved: "",
    nitrite: "",
    ammonia: "",
    temperature: "",
    turbidity: "",
  });

  const [chartShow, setChartShow] = useState(true);
  const [chartData, setChartData] = useState(null);

  // useEffect(() => {
  //   async function handleEscapeKey(event) {
  //     if (event.code === "Enter") {
  //       await onClickUpdateBiomass();
  //     }
  //   }
  //   document.addEventListener("keydown", handleEscapeKey);
  //   return () => document.removeEventListener("keydown", handleEscapeKey);
  // }, []);


  const [waterQualitys, setWaterQualitys] = useState();

  useEffect(() => {
    renderWaterQuality();
  }, []);


  useEffect(() => {
    if (waterQualitys) {
      getWaterRecords();
    }
  }, [waterQualitys])

  const renderWaterQuality = async () => {
    try {
      let data = await getWaterQuality(window.history.state.usr.cycleData.pondId);
      setWaterQualitys(data);
    } catch (err) {
      console.log(err);
    }
  }

  const getWaterRecords = async () => {
    try {
      let data = await getWaterParametersAllCycle(
        window.history.state.usr.cycleData
          ? window.history.state.usr.cycleData.id
          : null
      );

      data.sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      });

      let uid = currentUser?.uid
      let userData = await getUserData(uid);
      setUser(userData);
      if (data.length > 0) {
        setRecordList(data);
      } else {
        setIsData(true);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (addInput) {
      getWaterCharts();
    }
  }, [addInput]);

  const getWaterCharts = async () => {
    try {
      let data = await getLastDaysData(window.history.state.usr.cycleData
        ? window.history.state.usr.cycleData.id : null, 14)
      setChartData(data)
    } catch (error) {
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setAddInput((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onClickUpdateBiomass = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    try {
      if (isDisable) {
      } else {
        setIsOpenModal(false);
        setIsUpdateLoader(true);
        if (
          window.history.state.usr.cycleData &&
          window.history.state.usr.cycleData.id
        ) {
          let data = {
            ammonia: addInput.ammonia,
            dissolvedOxygen: addInput.dissolved,
            isFromDevice: false,
            nitrite: addInput.nitrite,
            ph: addInput.ph,
            pondId: window.history.state.usr.cycleData.pondId,
            salinity: addInput.salinity,
            temperature: addInput.temperature,
            turbidity: addInput.turbidity,
            timestamp: getCurrentTimestampInMillis(),
            userId: user.uid,
            userName: user.fullName,
          };
          const addId = await addWaterParamRecord(
            currentUser?.pId,
            window.history.state.usr.cycleData.id,
            data
          );
          let list = recordList;
          list.unshift({ ...data, id: addId });
          setRecordList(list);
        } else {
          alert(t("ponds.no_active_cycle_found_in_this_pond"));
        }
        // setNoOfBiomass2("");
        setAddInput({
          ph: "",
          salinity: "",
          dissolved: "",
          nitrite: "",
          ammonia: "",
          temperature: "",
          turbidity: ""
        });
        setIsOpenModal(false);
        setIsDisable(false);
        setIsUpdateLoader(false);
      }
    } catch (error) {
      console.log('error: ', error);
      setIsDisable(false);
    }
  };

  const checkExceededOrNotBG = (value, valueMinimum, valueMaximum) => {
    let className = "";
    if (value) {
      if (valueMinimum && Number(value) < Number(valueMinimum)) {
        className = "active-bg-red";
      }
      if (valueMaximum && Number(value) > Number(valueMaximum)) {
        className = "active-bg-red";
      }
    }
    return className;
  }

  const checkExceededOrNotIcon = (value, valueMinimum, valueMaximum) => {
    let icon = null;
    if (value) {
      if (valueMinimum && Number(value) < Number(valueMinimum)) {
        icon = <span><SouthIcon /></span>;
      }
      if (valueMaximum && Number(value) > Number(valueMaximum)) {
        icon = <span><NorthIcon /></span>;
      }
    }
    return icon;
  }


  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      //   headText={`${t("ponds.record_for_pond")}: ${
      //     window.history.state.usr.cardData.pondName
      //   }`}
      headText={t("home.water_parameters")}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: window.history.state.usr.cardData,
            cycleData: window.history.state.usr.cycleData,
          },
        });
      }}
    >
      {/* ----- HEADER ----- */}
      <div className={`head_container charts-mian-block ${chartShow ? "charts-cm" : ""}`} style={{ marginTop: -20 }}>
        <div className="head_one">
          <button
            className="ui primary button"
            style={{ width: 120, marginLeft: 5 }}
            onClick={() => {
              chartShow ? setChartShow(false) : setChartShow(true)
            }}
          >
            {!chartShow ? t("ponds.view_charts") : t("ponds.view_records")}
          </button>
        </div>
        <div className="head_two" style={{ marginLeft: -2 }}>
          <button
            className="ui primary button"
            style={{ width: 120, marginLeft: 5 }}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            {t("ponds.add")}
          </button>
        </div>
      </div>
      {/* ----- TABLE ----- */}
      {recordList.length > 0 ? (
        <div
          style={{
            width: "100%",
            overflow: "auto",
            marginTop: 30,
            height: "100vh"
          }}
        >
          {!chartShow ? (
            <table
              className="ui celled table user-manage-tab"
              style={{ marginTop: 0 }}
            >
              <thead>
                <tr>
                  <th>{t("home.time")}</th>
                  <th>{t("ponds.user_name")}</th>
                  <th>{t("ponds.ph")}</th>
                  <th>{t("ponds.salinity")}</th>
                  <th>{t("ponds.dissolved_oxygen")}</th>
                  <th>{t("ponds.nitrite")}</th>
                  <th>{t("ponds.ammonia")}</th>
                  <th>{t("ponds.temperature")}</th>
                  <th>{t("ponds.turbidity")}</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {waterQualitys && recordList.map((val, index) => {
                  return (
                    <tr
                      // style={{ backgroundColor: val.color }}
                      key={Math.random()} >
                      <td
                        data-label={t("home.time")}
                        style={{ verticalAlign: "middle" }}
                      >
                        {getTimestampWithFormatDate(val.timestamp,"DD-MM-YYYY @ hh:mma")}
                      </td>
                      <td
                        data-label={t("ponds.biomass")}
                        style={{ verticalAlign: "middle" }}
                      >
                        {val.userName}
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.ph, waterQualitys.phMinimum, waterQualitys.phMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.ph).toFixed(2)}
                          {checkExceededOrNotIcon(val.ph, waterQualitys.phMinimum, waterQualitys.phMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.salinity, waterQualitys.salinityMinimum, waterQualitys.salinityMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.salinity).toFixed(2)}
                          {checkExceededOrNotIcon(val.salinity, waterQualitys.salinityMinimum, waterQualitys.salinityMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.dissolvedOxygen, waterQualitys.dissolvedOxygenMinimum, waterQualitys.dissolvedOxygenMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.dissolvedOxygen).toFixed(2)}
                          {checkExceededOrNotIcon(val.dissolvedOxygen, waterQualitys.dissolvedOxygenMinimum, waterQualitys.dissolvedOxygenMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.nitrite, waterQualitys.nitriteMinimum, waterQualitys.nitriteMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.nitrite).toFixed(2)}
                          {checkExceededOrNotIcon(val.nitrite, waterQualitys.nitriteMinimum, waterQualitys.nitriteMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.ammonia, waterQualitys.ammoniaMinimum, waterQualitys.ammoniaMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.ammonia).toFixed(2)}
                          {checkExceededOrNotIcon(val.ammonia, waterQualitys.ammoniaMinimum, waterQualitys.ammoniaMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.temperature, waterQualitys.temperatureMinimum, waterQualitys.temperatureMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.temperature).toFixed(2)}
                          {checkExceededOrNotIcon(val.temperature, waterQualitys.temperatureMinimum, waterQualitys.temperatureMaximum)}
                        </div>
                      </td>
                      <td
                        data-label={t("ponds.user")}
                        style={{ verticalAlign: "middle" }}
                        className={checkExceededOrNotBG(val.turbidity, waterQualitys.turbidityMinimum, waterQualitys.turbidityMaximum)}
                      >
                        <div className="td-row-arrow">
                          {Number(val.turbidity).toFixed(2)}
                          {checkExceededOrNotIcon(val.turbidity, waterQualitys.turbidityMinimum, waterQualitys.turbidityMaximum)}
                        </div>
                      </td>
                      <td>
                        <div
                          style={style.container}
                          className="farm-svg-css delete_icon_td"
                        >
                          <div className="look_icon_css red_icon_eye">
                            <Delete
                              sx={{ marginLeft: 2 }}
                              onClick={() => {
                                setDeleteData({
                                  index: index,
                                  ...val,
                                });
                                setDeleteModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="charts-row">
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.ph")}
                          labelText={t("ponds.average_ph")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.ph ? chartData.ph : []}
                          waterQualityMin={waterQualitys && waterQualitys.phMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.phMaximum}
                          minimumText={"PH Minimum"}
                          maximumText={"PH Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.salinity")}
                          labelText={t("ponds.average_salinity")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.salinity ? chartData.salinity : []}
                          waterQualityMin={waterQualitys && waterQualitys.salinityMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.salinityMaximum}
                          minimumText={"Salinity Minimum"}
                          maximumText={"Salinity Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.dissolved_oxygen")}
                          labelText={t("ponds.average_dissolved_oxygen")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.dissolvedOxygen ? chartData.dissolvedOxygen : []}
                          waterQualityMin={waterQualitys && waterQualitys.dissolvedOxygenMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.dissolvedOxygenMaximum}
                          minimumText={"Dissolved Oxygen Minimum"}
                          maximumText={"Dissolved Oxygen Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.nitrite")}
                          labelText={t("ponds.average_nitrite")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.nitrite ? chartData.nitrite : []}
                          waterQualityMin={waterQualitys && waterQualitys.nitriteMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.nitriteMaximum}
                          minimumText={"Nitrite Minimum"}
                          maximumText={"Nitrite Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.ammonia")}
                          labelText={t("ponds.average_ammonia")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.ammonia ? chartData.ammonia : []}
                          waterQualityMin={waterQualitys && waterQualitys.ammoniaMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.ammoniaMaximum}
                          minimumText={"Ammonia Minimum"}
                          maximumText={"Ammonia Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.temperature")}
                          labelText={t("ponds.average_temperature")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.temperature ? chartData.temperature : []}
                          waterQualityMin={waterQualitys && waterQualitys.temperatureMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.temperatureMaximum}
                          minimumText={"Temperature Minimum"}
                          maximumText={"Temperature Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-col">
                <div className="view_more_row view_more_chart">
                  <div className="line_chart_main_block">
                    <div className="line_chart_main">
                      <div className="chart_block">
                        <LineChart
                          titleText={t("ponds.turbidity")}
                          labelText={t("ponds.average_turbidity")}
                          labelsData={chartData && chartData.dates ? chartData.dates : []}
                          chartData={chartData && chartData.turbidity ? chartData.turbidity : []}
                          waterQualityMin={waterQualitys && waterQualitys.turbidityMinimum}
                          waterQualityMax={waterQualitys && waterQualitys.turbidityMaximum}
                          minimumText={"Turbidity Minimum"}
                          maximumText={"Turbidity Maximum"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : isData ? (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
            {t("ponds.biomass_not_found_msg")}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )
      }
      {
        isUpdateLoader && (
          <div
            style={{
              backgroundColor: "black",
              opacity: 0.5,
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          ></div>
        )
      }
      {
        isUpdateLoader && (
          <div
            style={{
              position: "absolute",
              zIndex: 55,
              top: 45,
              left: 90,
              display: "flex",
              height: "100vh",
              // height: '100%',
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        )
      }

      {/* ------ ADD MODAL ------ */}
      <Modal
        open={isOpenModal}
        onClose={() => {
          if (isDisable) {
          } else {
            // setNoOfBiomass2("");
            setIsOpenModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form>
          <Box sx={boxStyle} className="logout-css-modal-block">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ paddingTop: 2, textAlign: "center", fontWeight: "600" }}
            >
              {t("ponds.add") + " " + t("home.parameters")}
            </Typography>
            <form className="parameters_form">
              <div className="add_parameters_input">
                <label>{t("ponds.ph")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.ph")}
                  name="ph"
                  value={addInput.ph}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.salinity")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.salinity")}
                  name="salinity"
                  value={addInput.salinity}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.dissolved_oxygen")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.dissolved_oxygen")}
                  name="dissolved"
                  value={addInput.dissolved}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.nitrite")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.nitrite")}
                  name="nitrite"
                  value={addInput.nitrite}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.ammonia")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.ammonia")}
                  name="ammonia"
                  value={addInput.ammonia}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.temperature")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.temperature")}
                  name="temperature"
                  value={addInput.temperature}
                  onChange={onInputChange}
                />
              </div>
              <div className="add_parameters_input">
                <label>{t("ponds.turbidity")}</label>
                <input
                  type="number"
                  placeholder={t("ponds.enter") + " " + t("ponds.turbidity")}
                  name="turbidity"
                  value={addInput.turbidity}
                  onChange={onInputChange}
                />
              </div>
            </form>


            {/* <div
              className="ui input"
              style={{
                width: "100%",
                marginTop: 15,
                marginBottom: 30,
              }}
            >
              <input
                onChange={(e) => setNoOfBiomass2(e.target.value)}
                value={noOfBiomass2}
                id="biomass2"
                type="number"
                placeholder="Please enter biomass"
                required
              />
            </div> */}

            <div className="logout-button-block submit-logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    // setNoOfBiomass2("");
                    setIsOpenModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <span></span>
              <button
                type="submit"
                autoFocus
                onClick={onClickUpdateBiomass}>
                {t("ponds.add")}
              </button>
            </div>
          </Box>
        </form>
      </Modal>

      {/* ----- DELETE MODAL ----- */}
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("ponds.are_you_sure_you_want_to_delete_this")}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setDeleteModal(false);
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              className="delete_bg_color"
              sx={[textStyle, { color: "red" }]}
              onClick={async () => {
                setIsUpdateLoader(true);
                setDeleteModal(false);
                if (isDisable) {
                } else {
                  try {
                    const num = noOfBiomass - deleteData.biomass;
                    const data = await deleteWaterRecord(
                      currentUser.pId,
                      window.history.state.usr.cycleData.id,
                      deleteData
                    );
                    if (data) {
                      let list = recordList.filter((ele) => {
                        return ele.id !== deleteData.id;
                      });
                      setRecordList(list);
                    }
                    setIsDisable(false);
                    setDeleteModal(false);
                    // setDeleteModal(false);
                    setIsUpdateLoader(false);
                  } catch (error) {
                    console.log('error: ', error);
                    setIsDisable(false);
                  }
                }
              }}
            >
              {t("ponds.delete")}
            </Typography>
          </div>
        </Box>
      </Modal>
    </ResponsiveDrawer>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 375 ? 400 : 270,
  // width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default ParametersRecords;
