import {
  addDoc,
  limit,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  // onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const getAllAlarmData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await getDocs(collection(db, "alarms"));
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      return resolve(data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateAlarmById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "alarms", id);
        await updateDoc(docRef, data);
        return resolve("Updated Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAlarmByPondId = (pId, id, onlyAlarms = false, pondsAll = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!id || id == "none") {
        let q = query(
          collection(db, "alarms"),
          where("pId", "==", pId),
          orderBy("recordDate", "desc")
        );
        if (pondsAll && pondsAll.length > 0) {
          q = query(
            collection(db, "alarms"),
            where("pId", "==", pId),
            where("pondId", "in", pondsAll),
            orderBy("recordDate", "desc")
          );
        }

        const currentCycleIdsSnap = query(
          collection(db, "Ponds"),
          where("pId", "==", `${pId}`),
          where("currentCycleId", "!=", "")
        );
        let dataMain = [];
        let data = [];
        let activeCycles = [];
        // onSnapshot(q, (snapshot) => {
        //   snapshot.docs.forEach((element) => {
        //     if (element.exists()) {
        //       data.push({ id: element.id, ...element.data() });
        //     }
        //   });
        //   return resolve(data);
        // });
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        const qCycles = await getDocs(currentCycleIdsSnap);
        qCycles.forEach((val) => {
          const pondCycleFound = activeCycles.some((obj) => obj === val.data().currentCycleId);
          if (!pondCycleFound || pondCycleFound.length <= 0) {
            activeCycles.push(val.data().currentCycleId);
          }
        });
        for (const cycleId of activeCycles) {
          for (const alarm of data) {
            if (alarm.cycleId == cycleId) {
              dataMain.push(alarm);
            }
          }
        }
        return resolve(dataMain);
      } else {
        const q = query(
          collection(db, "alarms"),
          where("pondId", "==", id),
          orderBy("recordDate", "desc")
        );
        let dataMain = [];
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        if (!onlyAlarms) {
          const currentCycleIdsSnap = doc(db, "Ponds", id);
          let activeCycles = [];
          const qCycles = await getDoc(currentCycleIdsSnap);
          activeCycles.push(qCycles.data().currentCycleId);
          for (const cycleId of activeCycles) {
            for (const alarm of data) {
              if (alarm.cycleId == cycleId) {
                dataMain.push(alarm);
              }
            }
          }
          return resolve(dataMain);
        } else {
          return resolve(data);
        }
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAlarmByCycleId = (pId, id, onlyAlarms = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!id || id == "none") {
        const q = query(
          collection(db, "alarms"),
          where("pId", "==", pId),
          orderBy("recordDate", "desc")
        );
        const currentCycleIdsSnap = query(
          collection(db, "Ponds"),
          where("pId", "==", `${pId}`),
          where("currentCycleId", "!=", "")
        );
        let dataMain = [];
        let data = [];
        let activeCycles = [];
        // onSnapshot(q, (snapshot) => {
        //   snapshot.docs.forEach((element) => {
        //     if (element.exists()) {
        //       data.push({ id: element.id, ...element.data() });
        //     }
        //   });
        //   return resolve(data);
        // });
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        const qCycles = await getDocs(currentCycleIdsSnap);
        qCycles.forEach((val) => {
          activeCycles.push(val.data().currentCycleId);
        });
        for (const cycleId of activeCycles) {
          for (const alarm of data) {
            if (alarm.cycleId == cycleId) {
              dataMain.push(alarm);
            }
          }
        }
        return resolve(dataMain);
      } else {
        const q = query(
          collection(db, "alarms"),
          where("cycleId", "==", id),
          orderBy("recordDate", "desc")
        );
        let dataMain = [];
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        if (!onlyAlarms) {
          const currentCycleIdsSnap = doc(db, "Ponds", id);
          let activeCycles = [];
          const qCycles = await getDoc(currentCycleIdsSnap);
          activeCycles.push(qCycles.data().currentCycleId);
          for (const cycleId of activeCycles) {
            for (const alarm of data) {
              if (alarm.cycleId == cycleId) {
                dataMain.push(alarm);
              }
            }
          }
          return resolve(dataMain);
        } else {
          return resolve(data);
        }
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// --------------------------- ALARM CREATION ---------------------------
export const createLinkRecords = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (data) {
        const addID = await addDoc(collection(db, "linkSharingRecords"), data);
        return resolve(addID.id);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const updateLinkRecords = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "linkSharingRecords", `${id}`);
        await updateDoc(docRef, data);
        return resolve(docRef.id);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const getLinkRecords = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const q = query(
          collection(db, "linkSharingRecords"),
          where("linkRecordId", "==", id),
          limit(1)
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data[0]);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const getAlarmsById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "alarms", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};
