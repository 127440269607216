import {
  ArrowDropDown,
  ArrowDropUp,
  Save,
  Create,
  CopyAll,
  Done,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  InputBase,
  Modal,
  NativeSelect,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import {
  getAlarmByPondId,
  updateAlarmById,
  getLinkRecords,
  createLinkRecords,
  updateLinkRecords,
} from "../../database/user/alarm";
import "../../style/loader.css";
// import '../../style/user/Ponds.css';
import colors from "../../common/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import imagePath from "../../common/imagePath";
import { getPond, updateRecord, getFarms } from "../../database/user/pond";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import no_alarms_found from "../../images/no_alarms_found.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PondRecodeMap from "./map/PondRecodeMap";
import { getCurrentTimestamp, getTimestampWithFormatDate, getCurrentTimestampInMillis } from "../../common/utils";
import { UTC } from "../../common/configs";
import { useAuth } from "../../common/AuthContext";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: 15,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const BootstrapInputForStatus = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    // border: "1px solid #ced4da",
    border: "none",
    fontSize: 15,
    // padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function CustomSorting({ title, showArrow }) {
  return (
    <div style={style.tableHeadContainer}>
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      {showArrow ? (
        <ArrowDropDown sx={{ width: 25, height: 25 }} />
      ) : (
        <ArrowDropUp sx={{ width: 25, height: 25 }} />
      )}
    </div>
  );
}

function Alarm() {
  const navigation = useNavigate();
  const {currentUser} = useAuth();
  const { t } = useTranslation();
  const [isData, setIsData] = useState(true);
  const [sortId, setSortId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [picture, setPicture] = useState(null);
  const [isOpenPic, setIsOpenPic] = useState(false);
  const [isShowAppBar, setIsShowAppBar] = useState(true);
  const [pondList, setPondList] = useState([]);

  const [cycleList, setCycleList] = useState(
    window.history.state.usr && window.history.state.usr.cycle
      ? window.history.state.usr.cycle
      : []
  );
  const [sortPond, setSortPond] = useState(
    window.history.state.usr && window.history.state.usr.pond
    ? window.history.state.usr.pond.id
      : "none"
  );
  const [sortCycle, setSortCycle] = useState(
    window.history.state.usr && window.history.state.usr.selectedCycle
      ? window.history.state.usr.selectedCycle.id
      : ""
  );
  const [alarmList, setAlarmList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [isLoader1, setIsLoader1] = useState(false);
  const [onlySelectPond, setOnlySelectPond] = useState(
    window.history.state.usr && window.history.state.usr.pond
      ? window.history.state.usr.pond.id
      : false
  );
  const [modalData, setModalData] = useState(null);
  const [openModalData, setOpenModalData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const shrimpStatusList = [
    { value: "Yes Lethal", label: t("alarm.yes_lethal") },
    { value: "Yes Non-lethal", label: t("alarm.yes_non_lethal") },
    { value: "No", label: t("ponds.no") },
    { value: "Check Needed", label: t("ponds.check_needed") },
  ];
  const typeList = [
    { value: "Counter", label: t("ponds.Counter") },
    { value: "Phone", label: t("ponds.Phone") },
    { value: "Probe", label: t("ponds.Probe") },
  ];
  const [shrimpSickStatus, setShrimpSickStatus] = useState(t("ponds.no"));
  const [type, setType] = useState(t("ponds.Counter"));
  const [comment, setComment] = useState("");
  const [showError, setShowError] = useState({
    isDays: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(null);
  const [isAlarmByCycle, setIsAlarmByCycle] = useState(false);
  const [isSortTime, setIsSortTime] = useState(false);
  const [isSortPond, setIsSortPond] = useState(false);
  const [isSortType, setIsSortType] = useState(false);
  const [isSortStatus, setIsSortStatus] = useState(false);
  const [typeSortList, setTypeSortList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [listForSearch, setListForSearch] = useState([]);
  const [linkRecordId, setLinkRecordId] = useState("");
  const [recordLink, setRecordLink] = useState("");
  const [isCopied, setCopied] = useState(false);
  const [linkId, setLinkId] = useState("");
  const [isCreateModal, setIsCreateLinkModal] = useState(false);
  const [days, setDays] = useState(14);
  const [farms, setFarms] = useState([]);

  const mainAlarmList =
    alarmList.length > 0 ? alarmList : isAlarmByCycle ? [] : [];

  const increment = () => {
    setDays(function (prevCount) {
      prevCount = Number(prevCount);
      if (prevCount >= 90) {
        return prevCount;
      }
      return (prevCount += 1);
    });
  };

  const decrement = () => {
    setDays(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  };

  useEffect(() => {
    if (mainAlarmList && mainAlarmList.length > 0) {
      let typeList = [];
      const data = mainAlarmList;
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let obj = data[i];
          if (data[i].shrimpSickStatus === "Yes") {
            if (data[i].isLethal === true) {
              typeList.push({ ...obj, index: 2 });
            } else {
              typeList.push({ ...obj, index: 3 });
            }
          } else if (data[i].shrimpSickStatus === "Check needed") {
            typeList.push({ ...obj, index: 1 });
          }
        }
      }
      setTypeSortList(typeList);
    }
  }, [mainAlarmList]);

  useEffect(() => {
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    try {
      const farms = await getFarms(currentUser);
      setFarms(farms);
    } catch (e) {
      console.log(e);
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (farms && farms.length > 0) {
      getAlarms(sortPond);
    }
  }, [farms]);
  const getAlarms = async (pondId) => {
    try {
      setIsLoader(true);
      let pId = currentUser?.pId

      let pondsData = await getPond(pId, null, farms);
      let noneObject = { id: "none", pondName: "All" };
      let pondsCon = [];
      pondsCon.push(noneObject);
      let ponds = pondsCon.concat(pondsData);
      if (ponds.length > 0) {
        ponds.sort((a, b) => {
          return a.pondName - b.pondName;
        });
        setPondList(ponds);
        setOnlySelectPond(false);
        let pondIds = [];
        for (const pond of ponds) {
          pondIds.push(pond.id);
        }
        let data = await getAlarmByPondId(currentUser?.pId, pondId, false, pondIds);
        setAlarmList(data);
        setListForSearch(data);
      }
      setIsLoader(false);
      // setIsData(true);
    } catch (error) {
      console.log("error: ", error);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (alarmList.length > 0) {
      let timeOutId = setTimeout(() => {
        setSearch(searchText);
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  useEffect(() => {
    if (search) {
      let data = mainAlarmList.filter((val) => {
        if (
          val.pondName.toLowerCase().includes(search.toLowerCase()) ||
          val.comment.toLowerCase().includes(search.toLowerCase())
        ) {
          return val;
        }
      });
      setAlarmList(data);
      if (data.length <= 0) {
        // setIsData(true);
      }
    }
  }, [search]);

  const onClickEdit = async () => {
    setIsDisable(true);
    try {
      const userId = currentUser?.uid
      const data = {
        EditTimeStamp: Number(getCurrentTimestampInMillis()),
        EditByUserId: userId,
        edited: true,
        type: type,
        comment: comment,
        isLethal:
          shrimpSickStatus === "Yes Lethal"
            ? true
            : shrimpSickStatus === "Yes Non-lethal"
            ? false
            : false,
        shrimpSickStatus:
          shrimpSickStatus === "Yes Non-lethal"
            ? "Yes"
            : shrimpSickStatus === "Yes Lethal"
            ? "Yes"
            : shrimpSickStatus,
      };
      const updated = await updateRecord(idForUpdate, data);
      if (updated === null) {
        return alert("Something went wrong. Please try again later.");
      }
      setIsOpenModal(false);
      setIsDisable(false);
      window.location.reload(false);
    } catch (error) {
      setIsDisable(false);
    }
  };

  const onCreateLinkClicked = async (id) => {
    // setIsLoader1(true);
    const linkData = await getLinkRecords(id);
    if (linkData) {
      setLinkId(linkData.id);
      const expireOn = getCurrentTimestampInMillis();
      setIsLoader1(false);
      if (linkData.expireOn > expireOn) {
        setRecordLink(linkData.link);
        setDays(linkData.days ? Number(linkData.days) : 14);
      } else {
        setRecordLink(null);
        setCopied(false);
        setIsLoader1(false);
        setDays(14);
      }
    } else {
      setDays(14);
      setRecordLink(null);
      setCopied(false);
      setIsLoader1(false);
    }
    setIsCreateLinkModal(true);
  };

  const createLink = async () => {
    if (recordLink) {
      setRecordLink(null);
      setCopied(false);
      return;
    }

    if (!linkRecordId) {
      return;
    }
    setIsDisable(true);
    if (days) {
      var expireOn = moment().add(Number(days), "days");
      let link = "http://localhost:3000";
      if (process.env.NODE_ENV == "production") {
        link = window.location.origin;
      }
      link = link + `/viewimages/${linkRecordId}`;
      const data = {
        expireOn: expireOn.tz(UTC).valueOf(),
        link: link,
        type: "alarm",
        days: days,
        linkRecordId: linkRecordId,
      };
      let id = "";
      if (!linkId) {
        id = await createLinkRecords(data);
      } else {
        id = await updateLinkRecords(linkId, data);
      }

      if (id) {
        setLinkId(id);
        setRecordLink(id ? data.link : "");
      } else {
        return alert("Something went wrong. Please try again later.");
      }
      setIsDisable(false);
    } else {
      setShowError({ ...showError, isDays: true });
      setIsDisable(false);
    }
  };

  return (
    <div className={isLoader1 ? "overflow_block" : ""}>
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={t("alarm.alarm")}
        isHideToolBar={true}
        isBackArrow={
          window.history.state.usr && window.history.state.usr.selectedCycle
            ? true
            : false
        }
        onClickBack={() => {
          navigation(`/pond_detail`, {
            state: {
              cardData:
                window.history.state.usr && window.history.state.usr.pond,
            },
          });
        }}
      >
        {/* ----- SIDE VIEW ----- */}
        <div style={{ display: "flex", width: "100%", marginTop: -20 }}>
          <div style={{ width: "100%" }}>
            {/* ----- HEAD SEARCHES ----- */}
            <div className="head_container hedera_ui_css_block alarm_margin">
              <div className="hedera_flex_block_row">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="head_one select-main-block"
                >
                  <Box sx={{ minWidth: 260 }}>
                    <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                      {t("alarm.filter_by_pond")}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sortPond}
                        label="Age"
                        onChange={async (e) => {
                          try {
                            setCycleList([]);
                            setSortCycle("");
                            setAlarmList([]);
                            setIsAlarmByCycle(false);
                            setSortPond(e.target.value);
                            getAlarms(e.target.value);
                            if (e.target.value !== "") {
                              setOnlySelectPond(true);
                            } else {
                              setOnlySelectPond(false);
                            }
                          } catch (error) {}
                        }}
                        input={<BootstrapInput />}
                      >
                        {pondList.length > 0 &&
                          pondList.map((val) => {
                            if (val.pondName) {
                              return (
                                <MenuItem key={val.id} value={val.id}>
                                  {val.pondName}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="head_two">
                  <div className="column" style={{ width: "260px" }}>
                    <div
                      className="ui icon input input-css-block"
                      style={{ width: "260px" }}
                    >
                      <input
                        className="prompt"
                        type="text"
                        placeholder={t("ponds.search")}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.value === "") {
                            setAlarmList(listForSearch);
                            setSearchText(e.target.value);
                            // setIsData(false);
                          } else {
                            setSearchText(e.target.value);
                            // setIsData(false);
                          }
                        }}
                        value={searchText}
                        style={{ border: "1px solid #ced4da", height: 43 }}
                      />
                      <i className="search icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----- ALARMS LIST ----- */}
            <div className="cards_main_block">
              <div className="inventory_table_wrap">
                <div className="table-responsive cm_table_responsive">
                  {/* ----- TABLE ----- */}
                  {isLoader ? (
                    <div
                      style={{
                        display: "flex",
                        height: "64vh",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div id={`load`} className="loader" />
                    </div>
                  ) : onlySelectPond ? (
                    <div></div>
                  ) : mainAlarmList.length > 0 ? (
                    <table
                      className="ui celled table alarms-css-drop cm_table fold-table"
                      style={{ marginTop: 0 }}
                    >
                      <thead className="cm_thead">
                        <tr className="cm_thead_list th_list cm_name">
                          <th
                            style={{ verticalAlign: "middle" }}
                            className="th_list cm_name"
                          >
                            <div className="th_item_wrap">
                              <h6>{t("home.top")}</h6>
                            </div>
                          </th>
                          <th
                            style={{ verticalAlign: "middle" }}
                            className="th_list cm_name"
                          >
                            <div className="th_item_wrap">
                              <h6>{t("home.side")}</h6>
                            </div>
                          </th>
                          <th
                            className="th_list cm_name"
                            style={{ minWidth: 120, verticalAlign: "middle" }}
                            onClick={() => {
                              const filteredData =
                                alarmList.length > 0
                                  ? alarmList
                                  : mainAlarmList;
                              if (isSortTime) {
                                filteredData.sort((a, b) => {
                                  return a.recordDate - b.recordDate;
                                });
                                setIsSortTime(false);
                                setAlarmList(filteredData);
                              } else {
                                filteredData.sort((a, b) => {
                                  return b.recordDate - a.recordDate;
                                });
                                setIsSortTime(true);
                                setAlarmList(filteredData);
                              }
                            }}
                          >
                            <div className="th_item_wrap cursor_pointer">
                              <h6>
                                <CustomSorting
                                  title={t("home.time")}
                                  showArrow={isSortTime}
                                />
                              </h6>
                            </div>
                          </th>
                          <th
                            className="th_list cm_name"
                            style={{ minWidth: 100, verticalAlign: "middle" }}
                            onClick={() => {
                              const filteredData =
                                alarmList.length > 0
                                  ? alarmList
                                  : mainAlarmList;
                              if (isSortPond) {
                                filteredData.sort((a, b) => {
                                  return b.pondName.toLowerCase() <
                                    a.pondName.toLowerCase()
                                    ? 1
                                    : -1;
                                });
                                setIsSortPond(false);
                                setAlarmList(filteredData);
                              } else {
                                filteredData.sort((a, b) => {
                                  return a.pondName.toLowerCase() <
                                    b.pondName.toLowerCase()
                                    ? 1
                                    : -1;
                                });
                                setIsSortPond(true);
                                setAlarmList(filteredData);
                              }
                            }}
                          >
                            <div className="th_item_wrap cursor_pointer">
                              <h6>
                                <CustomSorting
                                  title={t("home.pond")}
                                  showArrow={isSortPond}
                                />
                              </h6>
                            </div>
                          </th>
                          <th
                            className="th_list cm_name"
                            style={{ minWidth: 100, verticalAlign: "middle" }}
                            onClick={() => {
                              const filteredData = typeSortList;

                              if (isSortType) {
                                filteredData.sort((a, b) => {
                                  return a.index - b.index;
                                });
                                setIsSortType(false);
                                setAlarmList(filteredData);
                              } else {
                                filteredData.sort((a, b) => {
                                  return b.index - a.index;
                                });
                                setIsSortType(true);
                                setAlarmList(filteredData);
                              }
                            }}
                          >
                            <div className="th_item_wrap cursor_pointer">
                              <h6>
                                <CustomSorting
                                  title={t("home.type")}
                                  showArrow={isSortType}
                                />
                              </h6>
                            </div>
                          </th>
                          <th
                            className="th_list cm_name"
                            style={{ minWidth: 120, verticalAlign: "middle" }}
                            onClick={() => {
                              const filteredData =
                                alarmList.length > 0
                                  ? alarmList
                                  : mainAlarmList;
                              if (isSortStatus) {
                                filteredData.sort((a, b) => {
                                  return b.status.toLowerCase() <
                                    a.status.toLowerCase()
                                    ? 1
                                    : -1;
                                });
                                setIsSortStatus(false);
                                setAlarmList(filteredData);
                              } else {
                                filteredData.sort((a, b) => {
                                  return a.status.toLowerCase() <
                                    b.status.toLowerCase()
                                    ? 1
                                    : -1;
                                });
                                setIsSortStatus(true);
                                setAlarmList(filteredData);
                              }
                            }}
                          >
                            {/* {t("home.status")} */}
                            <div className="th_item_wrap cursor_pointer">
                              <h6>
                                <CustomSorting
                                  title={t("home.status")}
                                  showArrow={isSortStatus}
                                />
                              </h6>
                            </div>
                          </th>
                          <th
                            style={{ verticalAlign: "middle" }}
                            className="th_list cm_name"
                          >
                            <div className="th_item_wrap">
                              <h6>{t("home.description")}</h6>
                            </div>
                          </th>
                          {/* <th
                            style={{ verticalAlign: "middle" }}
                            className="th_list cm_name"
                          >
                            <div className="th_item_wrap">
                              <h6>{t("ponds.id")}</h6>
                            </div>
                          </th> */}
                          <th
                            style={{ minWidth: 130, verticalAlign: "middle" }}
                            className="th_list cm_name"
                          >
                            <div className="th_item_wrap">
                              <h6>{t("home.reported_by")}</h6>
                            </div>
                          </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody className="cm_tbody">
                        {mainAlarmList.map((val, index) => {
                          return (
                            <>
                              <tr
                                key={index}
                                className={`cm_tr ${
                                  val.status === "Cleared" ||
                                  val.shrimpSickStatus === "No"
                                    ? "cm_colors_green"
                                    : val.shrimpSickStatus === "Yes"
                                    ? val.isLethal === true
                                      ? "cm_colors_lightRed"
                                      : "cm_colors_orange"
                                    : "cm_colors_yellow"
                                }`}
                              >
                                <td
                                  className="cm_td"
                                  style={{ verticalAlign: "middle" }}
                                  data-label={t("home.top")}
                                >
                                  <div style={style.container}>
                                    {val.topImage && val.topImage !== "" ? (
                                      <div>
                                        <img
                                          id={`load-${index}`}
                                          src={
                                            val.topImage
                                              ? val.topImage
                                              : imagePath.noImg
                                          }
                                          style={{ width: 25, height: 25 }}
                                          onClick={() => {
                                            setIsOpenPic(true);
                                            setPicture(val.topImage);
                                            setIsShowAppBar(false);
                                          }}
                                          onContextMenu="return false;"
                                          onError={(e) => {
                                            document
                                              .getElementById(`load-${index}`)
                                              .setAttribute(
                                                "src",
                                                imagePath.noImg
                                              );
                                          }}
                                        />
                                        {isOpenPic && (
                                          <Lightbox
                                            mainSrc={
                                              picture
                                                ? picture
                                                : imagePath.noImg
                                            }
                                            onCloseRequest={() => {
                                              setIsShowAppBar(true);
                                              setIsOpenPic(false);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <img
                                        src={imagePath.noImg}
                                        style={{ width: 25, height: 25 }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cm_td"
                                  style={{ verticalAlign: "middle" }}
                                  data-label={t("home.side")}
                                >
                                  <div style={style.container}>
                                    {val.sideImage && val.sideImage !== "" ? (
                                      <div>
                                        <img
                                          id={`load-${index}`}
                                          src={
                                            val.sideImage
                                              ? val.sideImage
                                              : imagePath.noImg
                                          }
                                          style={{ width: 25, height: 25 }}
                                          onClick={() => {
                                            setIsOpenPic(true);
                                            setPicture(val.sideImage);
                                            setIsShowAppBar(false);
                                          }}
                                          onContextMenu="return false;"
                                          onError={(e) => {
                                            // document.getElementById(
                                            //   `load-${index}`
                                            // ).style.display = "none";
                                            document
                                              .getElementById(`load-${index}`)
                                              .setAttribute(
                                                "src",
                                                imagePath.noImg
                                              );
                                          }}
                                        />
                                        {isOpenPic && (
                                          <Lightbox
                                            mainSrc={
                                              picture
                                                ? picture
                                                : imagePath.noImg
                                            }
                                            onCloseRequest={() => {
                                              setIsShowAppBar(true);
                                              setIsOpenPic(false);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <img
                                        src={imagePath.noImg}
                                        style={{ width: 25, height: 25 }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cm_td"
                                  data-label={t("home.time")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>
                                    {getTimestampWithFormatDate(val.recordDate,"DD-MM-YYYY hh:mma")}
                                  </h4>
                                </td>
                                <td
                                  className="cm_td"
                                  data-label={t("home.pond")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>{val.pondName ? val.pondName : "N/A"}</h4>
                                </td>
                                <td
                                  className="cm_td"
                                  data-label={t("home.type")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>
                                    <span></span>
                                    {val.shrimpSickStatus === "Yes"
                                      ? val.isLethal === true
                                        ? t("home.LETHAL")
                                        : t("home.NON_LETHAL")
                                      : val.shrimpSickStatus === "No"
                                      ? t("home.OK")
                                      : t("home.CHECK_NEEDED")}
                                  </h4>
                                </td>
                                <td
                                  className="cm_td"
                                  data-label={t("home.status")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>
                                    {!loader ? (
                                      <FormControl
                                        fullWidth
                                        sx={{ height: 20, marginTop: -0.45 }}
                                      >
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={val.status}
                                          onChange={async (e) => {
                                            setLoader(true);
                                            try {
                                              setSortId(val.id);
                                              const data = {
                                                status: e.target.value,
                                              };
                                              await updateAlarmById(
                                                val.id,
                                                data
                                              );
                                              // await getAlarms();

                                              // const list = alarmRecordList.map(
                                              const list = mainAlarmList.map(
                                                (ele) => {
                                                  let obj = ele;
                                                  if (ele.id === val.id) {
                                                    obj = {
                                                      ...ele,
                                                      status: e.target.value,
                                                    };
                                                  }
                                                  return obj;
                                                }
                                              );

                                              // setAlarmRecordList(list);
                                              setAlarmList(list);
                                              setLoader(false);
                                              setSortId(null);
                                            } catch (error) {
                                              setLoader(false);
                                              setSortId(null);
                                            }
                                          }}
                                          input={<BootstrapInputForStatus />}
                                        >
                                          <MenuItem value={"Active"}>
                                            {t("home.active")}
                                          </MenuItem>
                                          <MenuItem value={"Cleared"}>
                                            {t("home.cleared")}
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    ) : (
                                      sortId &&
                                      val.id === sortId && (
                                        <div
                                          style={{
                                            display: "flex",
                                            height: 30,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <div id={`load`} className="loader" />
                                        </div>
                                      )
                                    )}
                                  </h4>
                                </td>
                                <td
                                  className="cm_td"
                                  data-label={t("home.description")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>{val.comment ? val.comment : "N/A"}</h4>
                                </td>
                                {/* <td
                                  className="cm_td"
                                  data-label={t("ponds.id")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>
                                    {val.id}
                                  </h4>
                                </td> */}
                                <td
                                  className="cm_td"
                                  data-label={t("home.reported_by")}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <h4>
                                    {`${
                                      val.type === "Phone" ? `${t("ponds.user")}:` : `${t("device.device")}:`
                                    } ${
                                      val.reportedUser
                                        ? val.reportedUser
                                        : val.reportedBy
                                    }`}
                                  </h4>
                                </td>
                                {/* <td
                                  className="cm_td"
                                  data-label=""
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    onClick={() => {
                                      setModalData(val);
                                    }}
                                    sx={{
                                      color:
                                        val.shrimpSickStatus !== "Yes"
                                          ? "black"
                                          : "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {t("alarm.view")}
                                  </Typography>
                                </td> */}
                                <td
                                  className="cm_td"
                                  data-label=""
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* <Typography
                                    onClick={() => {
                                      setModalData(val);
                                    }}
                                  >
                                    {t("alarm.view")}
                                  </Typography> */}
                                  <p
                                    // onClick={() => {
                                    //   setModalData(val);
                                    // }}
                                    // onClick={() => {
                                    //   setModalData(val);
                                    //   setOpenModalData(true);
                                    // }}
                                    className="look_icon_css orange_icon_eye"
                                  >
                                    <LinkIcon
                                      onClick={() => {
                                        if (val.id != linkRecordId) {
                                          setLinkId(null);
                                          setRecordLink(null);
                                          setCopied(false);
                                        }
                                        setLinkRecordId(val.id);
                                        onCreateLinkClicked(val.id);
                                      }}
                                    />
                                  </p>
                                  <p
                                    // onClick={() => {
                                    //   setModalData(val);
                                    // }}
                                    onClick={() => {
                                      setModalData(val);
                                      setOpenModalData(true);
                                    }}
                                    style={{ marginRight: 8 }}
                                    className="look_icon_css blue_icon_eye"
                                  >
                                    <RemoveRedEyeIcon />
                                  </p>
                                  <p
                                    className="green_icon_edit"
                                    onClick={() => {
                                      setShrimpSickStatus(
                                        val.shrimpSickStatus === "Yes"
                                          ? val.isLethal
                                            ? "Yes Lethal"
                                            : "Yes Non-lethal"
                                          : val.shrimpSickStatus
                                      );
                                      setType(val.type);
                                      setComment(val.comment);
                                      setIdForUpdate(val.id);
                                      setIsOpenModal(true);
                                    }}
                                  >
                                    <EditOutlinedIcon />
                                  </p>
                                </td>
                              </tr>
                              <tr className="cm_hi"></tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        height: "65vh",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                      className="no-alarms-found-css"
                    >
                      <img src={no_alarms_found} alt="no_alarms_found image" />
                      <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                        {t("alarm.no_alarms_found")}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* ----- BUTTONS ----- */}
            <div style={style.buttonContainer}></div>

            {/* ----- EDIT MODAL ----- */}
            <Modal
              open={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={boxStyle} className="add-Pond-location">
                {/* <div style={{ height: "600px", overflow: "auto" }}> */}
                <div
                  className="hiding-pond-css"
                  style={{
                    display: "flex",
                    width: "100%",
                    backgroundColor: colors.slatery_blue,
                    justifyContent: "space-between",
                    padding: 8,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "white",
                      fontWeight: "600",
                      marginLeft: 1,
                    }}
                  >
                    {t("ponds.edit_record")}
                  </Typography>
                  <i
                    className="x icon"
                    style={{ color: "white", fontSize: 22 }}
                    onClick={() => {
                      if (isDisable) {
                      } else {
                        setIsOpenModal(false);
                      }
                    }}
                  />
                </div>

                <Box
                  sx={{
                    minWidth: 120,
                    marginTop: 1.5,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <Typography
                      sx={{
                        fontSize: 11,
                        color: "black",
                        opacity: 0.58,
                        fontWeight: "500",
                      }}
                    >
                      {t("ponds.select_shrimp_sick_status")}
                    </Typography>
                    <NativeSelect
                      onChange={(e) => {
                        setShrimpSickStatus(e.target.value);
                      }}
                      value={shrimpSickStatus}
                      inputProps={{
                        name: "select_shrimp_status",
                        id: "uncontrolled-native-shrimp-status",
                      }}
                      sx={{ fontSize: 16 }}
                    >
                      {shrimpStatusList.length > 0 &&
                        shrimpStatusList.map((val) => {
                          return (
                            <option key={val.value} value={val.value}>
                              {val.label}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    minWidth: 120,
                    marginTop: 1.5,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <Typography
                      sx={{
                        fontSize: 11,
                        color: "black",
                        opacity: 0.58,
                        fontWeight: "500",
                      }}
                    >
                      {t("ponds.select_type")}
                    </Typography>
                    <NativeSelect
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                      inputProps={{
                        name: "select_type",
                        id: "uncontrolled-native-type",
                      }}
                      sx={{ fontSize: 16 }}
                    >
                      {typeList.length > 0 &&
                        typeList.map((val) => {
                          return (
                            <option key={val.value} value={val.value}>
                              {val.label}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  </FormControl>
                </Box>

                <TextField
                  id="comment"
                  label={t("ponds.comment")}
                  type="text"
                  variant="standard"
                  required
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  value={comment}
                  sx={{
                    width: "95%",
                    fontSize: 18,
                    marginTop: 1.5,
                    marginLeft: 2,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <LoadingButton
                    onClick={onClickEdit}
                    loading={isDisable}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="contained"
                    sx={{ backgroundColor: colors.slatery_blue }}
                  >
                    {t("ponds.save")}
                  </LoadingButton>
                  {/* </div> */}
                </div>
              </Box>
            </Modal>
            {/* ----- LINK MODAL ----- */}

            {isLoader1 ? (
              <div
                className="link_loafer_block"
                style={{
                  display: "flex",
                  height: "50vh",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div id={`load`} className="loader" />
              </div>
            ) : (
              <Modal
                open={isCreateModal}
                onClose={() => setIsCreateLinkModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={boxStyle}
                  className="add-Pond-location add-Pond-location_popup"
                >
                  {/* <div style={{ height: "600px", overflow: "auto" }}> */}
                  <div
                    className="hiding-pond-css"
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: colors.slatery_blue,
                      justifyContent: "space-between",
                      padding: 8,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "white",
                        fontWeight: "600",
                        marginLeft: 1,
                      }}
                    >
                      {t("ponds.createALink")}
                    </Typography>
                    <i
                      className="x icon"
                      style={{ color: "white", fontSize: 22 }}
                      onClick={() => {
                        if (isDisable) {
                        } else {
                          setIsCreateLinkModal(false);
                        }
                      }}
                    />
                  </div>

                  {!recordLink ? (
                    <div className="expire_date_block">
                      <p>{t("ponds.expireInDays")}</p>
                      <div className="increment_decrement_main">
                      <p className="increment_decrement_button" onClick={decrement}>-</p>
                      <TextField
                        id="days"
                        type="number"
                        variant="standard"
                        required
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            if (e.target.value === "") {
                              setDays("");
                            } else {
                              if (
                                Number(e.target.value) > 0 &&
                                Number(e.target.value) <= 90
                              ) {
                                setDays(e.target.value);
                              }
                            }
                          }
                          setShowError({ ...showError, isDays: false });
                        }}
                        value={days}
                        sx={{
                          width: "95%",
                          fontSize: 18,
                          marginTop: 1.5,
                          marginLeft: 2,
                        }}
                      />
                      <p className="increment_decrement_button" onClick={increment}>+</p>
                      </div>
                      <p>{t("ponds.days")}</p>
                    </div>
                  ) : (
                    <div className="recordLink_row">
                      <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                        {t("ponds.recordLink")}
                      </Typography>
                      <a href={recordLink} target="/" sx={{ fontSize: 17 }}>
                        {recordLink}
                      </a>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <LoadingButton
                      onClick={createLink}
                      loading={isDisable}
                      loadingPosition="start"
                      startIcon={<Create />}
                      variant="contained"
                      sx={{ backgroundColor: colors.slatery_blue }}
                    >
                      {!recordLink ? t("ponds.creatLink") : t("ponds.edit")}
                    </LoadingButton>
                    {recordLink && (
                      <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          navigator.clipboard.writeText(recordLink);
                          setCopied(true);
                        }}
                        loading={isDisable}
                        loadingPosition="start"
                        startIcon={isCopied ? <Done /> : <CopyAll />}
                        variant="contained"
                        sx={{ backgroundColor: colors.slatery_blue }}
                      >
                        {isCopied ? t("ponds.copied") : t("ponds.copy")}
                      </Button>
                    )}
                    {/* </div> */}
                  </div>
                </Box>
              </Modal>
            )}
          </div>
        </div>
      </ResponsiveDrawer>

      <div>
        <Modal
          open={openModalData}
          onClose={() => setOpenModalData(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle} className="add-Pond-location">
            <div
              className="hiding-pond-css"
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginLeft: 1,
                }}
              >
                {t("alarm.alarm_details")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setOpenModalData(false);
                    setIsOpenModal(false);
                  }
                }}
              />
            </div>
            {modalData && (
              <div
                className="side_view_block pond_recode_map_auto"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowWrap: "break-word",
                }}
              >
                <div className="modal_image_row_cm">
                  <div
                    onClick={() => {
                      setIsOpenPic(true);
                      setPicture(modalData.topImage);
                      setIsShowAppBar(false);
                      setOpenModalData(false);
                    }}
                  >
                    <h4>{t("alarm.top_image")}</h4>
                    <img
                      src={
                        modalData
                          ? modalData.topImage
                            ? modalData.topImage
                            : imagePath.noImg
                          : imagePath.noImg
                      }
                    />
                  </div>
                  <div
                    onClick={() => {
                      setIsOpenPic(true);
                      setPicture(modalData.sideImage);
                      setIsShowAppBar(false);
                      setOpenModalData(false);
                    }}
                  >
                    <h4>{t("alarm.side_image")}</h4>
                    <img
                      src={
                        modalData
                          ? modalData.sideImage
                            ? modalData.sideImage
                            : imagePath.noImg
                          : imagePath.noImg
                      }
                    />
                  </div>
                </div>
                <div className="modal_list_alarm_row">
                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("home.pond")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData && modalData.pondName}
                      </span>
                    </Typography>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("ponds.cycle")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData && modalData.cycleNo}
                      </span>
                    </Typography>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("alarm.shrimp_health")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData
                          ? modalData.shrimpSickStatus === "Yes"
                            ? t("ponds.bad")
                            : modalData.shrimpSickStatus === "No"
                            ? t("ponds.good")
                            : modalData.shrimpSickStatus
                          : ""}
                      </span>
                    </Typography>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("alarm.alarm_status")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData && modalData.status}
                      </span>
                    </Typography>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("home.description")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData && modalData.comment
                          ? modalData.comment
                          : "-"}
                      </span>
                    </Typography>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("home.reported_by")}:&nbsp;{" "}
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {modalData &&
                          (modalData.reportedUser
                            ? modalData.reportedUser
                            : modalData.reportedBy)}
                      </span>
                    </Typography>
                  </div>
                </div>
                <div>
                  <PondRecodeMap modalData={modalData} />
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    // justifyContent: "flex-end",
    justifyContent: "center",
    alignItems: "flex-end",
    marginTop: 20,
  },
  tableHeadContainer: {
    display: "flex",
    justifyContent: "space-between",
    // verticalAlign: "middle",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 20,
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default Alarm;
