import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import CustomSegments from "../../components/CustomSegments";
import { getProfileData } from "../../database/user/profile";
import { Card } from "@mui/material";
import Profile from "./Profile";
import UserManagement from "./userManagement/UserManagement";
import Billing from "../billing/Billing";
import { useTranslation } from "react-i18next";
import { getPlansRecordsFromDb } from "../../database/user/home";
import { useAuth } from "../../common/AuthContext";

function Setting() {
  const { t } = useTranslation();
  const {currentUser,setCurrentUser} = useAuth();
  const [selectedSegment, setSelectedSegment] = useState(
    window.history.state.usr && window.history.state.usr.selected
      ? window.history.state.usr.selected
      : 1
  );
  const [segmentList, setSegmentList] = useState([
    { id: 1, title: t("settings.General") },
    // { id: 2, title: t("settings.Subscription_and_Billing_History") },
    { id: 3, title: t("settings.User_Management") },
  ]);

  const [profileData, setProfileData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [objCloud, setObjCloud] = useState(null);

  useEffect(() => {
    async function getDatas() {
      setIsLoader(true);
      const profile = await getProfileData(currentUser?.uid);
      let plansRecordsData = await getPlansRecordsFromDb(currentUser?.pId);
      if (plansRecordsData && plansRecordsData.type === "CloudLicense") {
        setObjCloud(plansRecordsData)
      }
      setProfileData(profile);
      if (currentUser.manager_user !== "true") {
        // if (profile.isSuperPremium) {
        //   setSegmentList([
        //     { id: 1, title: t("settings.General") },
        //     { id: 3, title: t("settings.User_Management") },
        //   ]);
        // } else {
        setSegmentList([
          { id: 1, title: t("settings.General") },
          // { id: 2, title: t("settings.Subscription_and_Billing_History") },
          { id: 3, title: t("settings.User_Management") },
        ]);
        // }
      } else {
        setSegmentList([
          { id: 1, title: t("settings.General") },
          // { id: 2, title: t("settings.Subscription_and_Billing_History") },
        ]);
      }
      if (profile && profile.isPremium) {
        setCurrentUser({...currentUser, premiumCust: "true"})
      }
      if (profile && profile.isSuperPremium) {
        setCurrentUser({...currentUser, premiumCust: "true"})
      }
      setIsLoader(false);
    }
    getDatas();
  }, []);

  useEffect(() => {
    let list = [
      { id: 1, title: t("settings.General") },
      { id: 2, title: t("settings.billing") },
    ];
    if (currentUser.manager_user !== "true") {
      list.push({ id: 3, title: t("settings.User_Management") });
    }
    setSegmentList(list);
  }, [currentUser.manager_user]);
  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("settings.settings")}
      isHideToolBar={true}
    >
      {isLoader ? (<div
          style={{
            display: "flex",
            height: "75vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
      </div>) : (
        <div>
          <CustomSegments
            segmentList={segmentList}
            selectedSegment={selectedSegment}
            onClickSelectedSegment={(ind) => {
              setSelectedSegment(ind);
            }}
          />
          {/* ----- GENERAL ----- */}
          <Card
            sx={{ padding: 2 }}
            clas
            sName="setting_main_box"
            className="setting_table_bg_block"
          >
            {selectedSegment === 1 && <Profile profile={profileData} />}
            {selectedSegment === 2 && (
              <Billing
                objCloud={objCloud}
                profile={profileData}
                selected={
                  window.history.state.usr && window.history.state.usr.selected
                }
              />
            )}
            {selectedSegment === 3 &&
              (currentUser?.manager_user !== "true" ? (
                <UserManagement
                  profile={profileData}
                  onSelectSegment={(selected) => {
                    setSelectedSegment(selected)
                  }}
                  onClickProfileUpdate={(data) => {
                    if (profileData) {
                      setSegmentList([
                        { id: 1, title: t("settings.General") },
                        // { id: 2, title: t("settings.Subscription_and_Billing_History") },
                        { id: 3, title: t("settings.User_Management") },
                      ]);
                      setProfileData({ ...profileData, ...data });
                    }
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "30vh",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                ></div>
              ))}
          </Card>
        </div>)}
    </ResponsiveDrawer>
  );
}

export default Setting;
