import {
  styled,
  InputBase,
  Box,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import colors from "../../common/colors";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import { getPond, getPondCycle, getShrimpWeightAllCycle, getWeeklyAverage, getFarms } from "../../database/user/pond";
import "../../style/loader.css";
import * as XLSX from "xlsx";
import {
  getPondRecordAllCycleById,
  getPondRecordCycleById,
} from "../../database/user/reports";
import "../../style/user/Reports.css";
import { t } from "i18next";
import { getAlarmByCycleId, getAlarmByPondId } from "../../database/user/alarm";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Multiselect } from "multiselect-react-dropdown";
import { Error } from "../../common/CustomToast";
import { getActivePondCycleInActive } from "../../../src/database/user/pond";
import { getTimestampWithFormatDate } from "../../common/utils";
import { useAuth } from "../../common/AuthContext";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function Report() {
  const {currentUser,setCurrentUser} = useAuth();
  const [isLoader, setIsLoader] = useState(true);
  const [isGenerateReportTab, setIsGenerateReportTab] = useState(currentUser?.pondActive === "0" ? "generate_report" : "Selected_pond");
  const [pondList, setPondList] = useState([]);
  const [pondListId, setPondListId] = useState();
  const [pondListSelected, setPondListSelected] = useState([]);
  const [sortPond, setSortPond] = useState("");
  const [pondName, setPondName] = useState("");
  const [isPondData, setIsPondData] = useState(false);
  const [cycleList, setCycleList] = useState([]);
  const [sortCycle, setSortCycle] = useState("");
  const [isCycleData, setIsCycleData] = useState(false);
  const [selectedCycleData, setSelectedCycleData] = useState(null);
  const [selectedPondData, setSelectedPondData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [isExportAllDisable, setIsExportAllDisable] = useState(false);

  const [pondInActiveCycle, setPondInActiveCycle] = useState([]);
  const [InActiveCycleSelected, setInActiveCycleSelected] = useState([]);
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    setCurrentUser({...currentUser, "pondActive" : "0" })
  })

  const [showError, setShowError] = useState({
    isFrom: false,
    isTo: false,
  });
  const navigation = useNavigate();
  const [monitoringType, setMonitoringType] = useState("HEALTH");

  const handleSelect = (selectedList) => {
    setPondListSelected(selectedList);
    setInActiveCycleSelected(selectedList);
  };

  const handleRemove = (selectedList) => {
    setPondListSelected(selectedList);
    setInActiveCycleSelected(selectedList);
  };

  useEffect(() => {
    markerClickHandler();
  }, [pondListId]);
  const markerClickHandler = async (event) => {
    try {
      if (!pondListId) {
        return
      }
      let data = await getActivePondCycleInActive(pondListId);
      setPondInActiveCycle(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    const farms = await getFarms(currentUser);
    setFarms(farms);
    if (!farms || farms.length <= 0) {
      setIsPondData(true);
    }
  }
  useEffect(() => {
    if (farms && farms.length > 0) {
      getDatas();
    }
  }, [farms]);
  const getDatas = async () => {
    try {
      let pId = currentUser?.pId

      let ponds = await getPond(pId, null, farms);

      if (ponds.length > 0) {
        ponds.sort((a, b) => {
          return a.pondName - b.pondName;
        });
        setPondList(ponds);
        setSelectedPondData(ponds[0]);
        setSortPond(ponds[0].id);
        setPondListId(ponds[0].id)
        setPondName(ponds[0].pondName);
        let cycles = await getPondCycle(ponds[0].id,currentUser.pId);
        if (cycles.length > 0) {
          cycles.sort((a, b) => {
            return a.cycleNo - b.cycleNo;
          });
          setCycleList(cycles);
          setSelectedCycleData(cycles[0]);
          setSortCycle(cycles[0].id);
        } else {
          setIsCycleData(true);
        }
      } else {
        setIsPondData(true);
      }
    } catch (error) {

    }
  };

  const getGraphData = async (cycleId) => {
    let forXAxis = [];
    let forYAxis = [];
    try {
      if (cycleId) {
        let shrimpWeightRateData = await getShrimpWeightAllCycle(cycleId);
        for (const shrimpWeightRate of shrimpWeightRateData) {
          forXAxis.push(
            getTimestampWithFormatDate(shrimpWeightRate.id,"DD MMM")
          );
          forYAxis.push(
            shrimpWeightRate.noOfRecord ? shrimpWeightRate.weight / shrimpWeightRate.noOfRecord : 0
          );
        }
      }
      return { forXAxis: forXAxis, forYAxis: forYAxis }
    } catch (error) {
      console.log(error);
      return { forXAxis: forXAxis, forYAxis: forYAxis }
    }
  };

  const getAlarmsCountCycle = async (id) => {
    let checkNeededCount = 0
    let lethalCount = 0
    let nonLethalCount = 0
    try {
      if (id) {
        const alarms = await getAlarmByCycleId(currentUser.pId,id, true);
        for (const alarm of alarms) {
          if (alarm.shrimpSickStatus == "Check needed") {
            checkNeededCount++
          }
          if (alarm.shrimpSickStatus == "Yes") {
            if (alarm.isLethal) {
              lethalCount++
            } else {
              nonLethalCount++
            }
          }
        }
      }
      return { checkNeededCount: checkNeededCount, lethalCount: lethalCount, nonLethalCount: nonLethalCount }
    } catch (error) {
      console.log(error);
      return { checkNeededCount: checkNeededCount, lethalCount: lethalCount, nonLethalCount: nonLethalCount }
    }
  };

  const getAlarmsCount = async (id) => {
    let checkNeededCount = 0
    let lethalCount = 0
    let nonLethalCount = 0
    try {
      if (id) {
        const alarms = await getAlarmByPondId(currentUser?.pId, id, true);
        for (const alarm of alarms) {
          if (alarm.shrimpSickStatus == "Check needed") {
            checkNeededCount++
          }
          if (alarm.shrimpSickStatus == "Yes") {
            if (alarm.isLethal) {
              lethalCount++
            } else {
              nonLethalCount++
            }
          }
        }
      }
      return { checkNeededCount: checkNeededCount, lethalCount: lethalCount, nonLethalCount: nonLethalCount }
    } catch (error) {
      console.log(error);
      return { checkNeededCount: checkNeededCount, lethalCount: lethalCount, nonLethalCount: nonLethalCount }
    }
  };

  const getServivalRate = async (harvestedAmount, averageSize, amountStocked) => {
    let sirvivalRate = 0
    try {
      sirvivalRate = (((harvestedAmount * 1000) / averageSize) / amountStocked) * 100
      return (sirvivalRate ? sirvivalRate.toFixed(1) : 0);
    } catch (error) {
      console.log(error);
      return sirvivalRate;
    }
  };

  const getStockingDensity = async (amountStocked, pondSize) => {
    let stockingDensity = 0
    try {
      stockingDensity = amountStocked / (pondSize * 10000)
      return (stockingDensity ? stockingDensity.toFixed(1) : 0);
    } catch (error) {
      console.log(error);
      return stockingDensity;
    }
  };

  const generateAllPondReport = async () => {
    setIsLoader(false);
    const pondList = pondListSelected
    const pondsNameString = Object.keys(pondList).map(function (k) { return pondList[k].pondName }).join(", ");
    //ALL REPORTS DATA
    let amountOfPonds = pondList.length
    let amountStockedTotal = 0, harvestedAmountTotal = 0, amountOfFeedTotal = 0, checkNeededCountTotal = 0, lethalCountTotal = 0, nonLethalCountTotal = 0;

    //ALL REPORTS GRAPH DATA
    let labels = [];
    let dataAmountStokced = [], dataAmountHarvested = [], dataAmountOfFeed = [], dataAmountOfLethalAlarms = [], dataAmountOfNonLethalAlarms = [], dataAmountOfCheckNeededAlarms = [], dataFeedConversion = [];

    for (const pond of pondList) {
      const pondData = pond
      let cycles = await getPondCycle(pondData.id,currentUser.pId, true);
      cycles.sort((a, b) => {
        return a.cycleNo - b.cycleNo;
      });
      //ALL REPORTS DATA
      let amountStockedAll = (cycles.reduce((totalBiomass, cycle) => totalBiomass + (cycle.biomass ? Number(cycle.biomass) : 0), 0))
      let amountStocked = cycles.length > 0 ? (amountStockedAll / cycles.length) : 0;
      amountStockedTotal = amountStockedTotal + amountStocked;
      let harvestedAmountAll = (cycles.reduce((total, cycle) => Number(total) + (cycle.harvestedAmount ? Number(cycle.harvestedAmount) : 0), 0))
      let harvestedAmount = cycles.length > 0 ? (harvestedAmountAll / cycles.length) : 0
      harvestedAmountTotal = harvestedAmountTotal + harvestedAmount;
      let amountOfFeedAll = (cycles.reduce((total, cycle) => Number(total) + (cycle.amountOfFeed ? Number(cycle.amountOfFeed) : 0), 0))
      let amountOfFeed = cycles.length > 0 ? (amountOfFeedAll / cycles.length) : 0
      amountOfFeedTotal = amountOfFeedTotal + amountOfFeed
      const { checkNeededCount, lethalCount, nonLethalCount } = await getAlarmsCount(pondData.id)
      checkNeededCountTotal = checkNeededCountTotal + checkNeededCount
      lethalCountTotal = lethalCountTotal + lethalCount
      nonLethalCountTotal = nonLethalCountTotal + nonLethalCount

      //ALL REPORT GRAPH
      labels.push(pond.pondName)
      dataAmountStokced.push(amountStocked)
      dataAmountHarvested.push(harvestedAmount);
      dataAmountOfFeed.push(amountOfFeed)
      dataAmountOfLethalAlarms.push(lethalCount)
      dataAmountOfNonLethalAlarms.push(nonLethalCount)
      dataAmountOfCheckNeededAlarms.push(checkNeededCount)
      let feedConversion = amountOfFeed / harvestedAmount
      dataFeedConversion.push(feedConversion.toFixed(1))
    }
    const data = {
      pondsNameString: pondsNameString,
      amountOfPonds: amountOfPonds,
      amountStocked: amountStockedTotal,
      harvestedAmount: harvestedAmountTotal,
      amountOfFeed: amountOfFeedTotal,
      checkNeededCountTotal: checkNeededCountTotal,
      lethalCountTotal: lethalCountTotal,
      nonLethalCountTotal: nonLethalCountTotal,
      isCycle: false
    }

    const graphs = {
      amountStockedGraph: { labels: labels, datasets: dataAmountStokced },
      amountHarvestedGraph: { labels: labels, datasets: dataAmountHarvested },
      amountOfFeedGraph: { labels: labels, datasets: dataAmountOfFeed },
      lethalAlarmsGraph: { labels: labels, datasets: dataAmountOfLethalAlarms },
      nonLethalAlarmsGraph: { labels: labels, datasets: dataAmountOfNonLethalAlarms },
      checkNeededAlarmsGraph: { labels: labels, datasets: dataAmountOfCheckNeededAlarms },
      feedConversionGraph: { labels: labels, datasets: dataFeedConversion }
    }
    const graphData = {
      data: data,
      graphs: graphs
    }

    setIsLoader(true);

    if (pondListSelected.length <= 0) {
      Error(t("report.please_select_pond"))
    } else {
      navigation("/report_all", {
        state: {
          reportData: graphData
        }
      });
    }


  };


  const generateAllPondCycleReport = async () => {
    setIsLoader(false);
    const cycleList = InActiveCycleSelected
    const cycleNameString = Object.keys(cycleList).map(function (k) { return cycleList[k].cycleName }).join(", ");
    //ALL REPORTS DATA
    let amountOfCycles = cycleList.length
    let amountStockedTotal = 0, harvestedAmountTotal = 0, amountOfFeedTotal = 0, checkNeededCountTotal = 0, lethalCountTotal = 0, nonLethalCountTotal = 0;

    // //ALL REPORTS GRAPH DATA
    let labels = [];
    let dataAmountStokced = [], dataAmountHarvested = [], dataAmountOfFeed = [], dataAmountOfLethalAlarms = [], dataAmountOfNonLethalAlarms = [], dataAmountOfCheckNeededAlarms = [], dataFeedConversion = [];

    for (const cycle of cycleList) {

      //ALL REPORTS DATA
      let amountStocked = (cycle.biomass ? Number(cycle.biomass) : 0);
      amountStockedTotal = amountStockedTotal + amountStocked;
      let harvestedAmount = cycle.harvestedAmount ? Number(cycle.harvestedAmount) : 0
      harvestedAmountTotal = harvestedAmountTotal + harvestedAmount;
      let amountOfFeed = cycle.amountOfFeed ? Number(cycle.amountOfFeed) : 0
      amountOfFeedTotal = amountOfFeedTotal + amountOfFeed
      const { checkNeededCount, lethalCount, nonLethalCount } = await getAlarmsCountCycle(cycle.id)
      checkNeededCountTotal = checkNeededCountTotal + checkNeededCount
      lethalCountTotal = lethalCountTotal + lethalCount
      nonLethalCountTotal = nonLethalCountTotal + nonLethalCount

      //ALL REPORT GRAPH
      labels.push(cycle.cycleName)
      dataAmountStokced.push(amountStocked)
      dataAmountHarvested.push(harvestedAmount);
      dataAmountOfFeed.push(amountOfFeed)
      dataAmountOfLethalAlarms.push(lethalCount)
      dataAmountOfNonLethalAlarms.push(nonLethalCount)
      dataAmountOfCheckNeededAlarms.push(checkNeededCount)
      let feedConversion = amountOfFeed / harvestedAmount
      dataFeedConversion.push(feedConversion.toFixed(1))
    }
    const data = {
      pondName: selectedPondData.pondName,
      pondsNameString: cycleNameString,
      amountOfPonds: amountOfCycles,
      amountStocked: amountStockedTotal,
      harvestedAmount: harvestedAmountTotal,
      amountOfFeed: amountOfFeedTotal,
      checkNeededCountTotal: checkNeededCountTotal,
      lethalCountTotal: lethalCountTotal,
      nonLethalCountTotal: nonLethalCountTotal,
      isCycle: true
    }

    const graphs = {
      amountStockedGraph: { labels: labels, datasets: dataAmountStokced },
      amountHarvestedGraph: { labels: labels, datasets: dataAmountHarvested },
      amountOfFeedGraph: { labels: labels, datasets: dataAmountOfFeed },
      lethalAlarmsGraph: { labels: labels, datasets: dataAmountOfLethalAlarms },
      nonLethalAlarmsGraph: { labels: labels, datasets: dataAmountOfNonLethalAlarms },
      checkNeededAlarmsGraph: { labels: labels, datasets: dataAmountOfCheckNeededAlarms },
      feedConversionGraph: { labels: labels, datasets: dataFeedConversion }
    }
    const graphData = {
      data: data,
      graphs: graphs
    }

    setIsLoader(true);

    if (pondListSelected.length <= 0) {
      Error(t("report.pleaseSelectCycle"))
    } else {
      navigation("/report_all", {
        state: {
          reportData: graphData
        }
      });
    }


  };

  const generatePondReport = async () => {
    // setIsLoader(false);
    const cycleData = selectedCycleData
    const pondData = selectedPondData
    if (pondData.currentCycleId == cycleData.id) {
      Error(t("report.select_non_active_cycles"));
      return;
    }
    const startDate = getTimestampWithFormatDate(cycleData.startDate,"DD/MM/YYYY");
    const endDate = cycleData.endDate ? getTimestampWithFormatDate(cycleData.endDate,"DD/MM/YYYY") : "Running"
    const pondName = pondData.pondName
    const cycleName = cycleData.cycleName
    const pondSize = pondData.pondSize
    const amountStocked = cycleData.biomass ? Number(cycleData.biomass) : 0
    const harvestedAmount = cycleData.harvestedAmount ? Number(cycleData.harvestedAmount) : 0
    const amountOfFeed = cycleData.amountOfFeed ? Number(cycleData.amountOfFeed) : 0
    const weeklyAverageGrowthRate = await getWeeklyAverage(cycleData.id)
    const { checkNeededCount, lethalCount, nonLethalCount } = await getAlarmsCount(pondData.id)
    const { forXAxis, forYAxis } = await getGraphData(cycleData.id)
    let averageSize = 0
    const totalWeight = cycleData.totalWeight ? Number(cycleData.totalWeight) : 0
    const noOfRecord = cycleData.noOfRecord ? Number(cycleData.noOfRecord) : 0
    if (noOfRecord > 0) {
      averageSize = (totalWeight / noOfRecord).toFixed(1)
    }
    const sirvivalRate = averageSize > 0 ? await getServivalRate(harvestedAmount, averageSize, amountStocked) : 0
    const stockingDensity = await getStockingDensity(amountStocked, pondSize)
    const reportData = {
      startDate: startDate,
      endDate: endDate,
      pondName: pondName,
      cycleName: cycleName,
      pondSize: pondSize,
      amountStocked: amountStocked,
      harvestedAmount: harvestedAmount,
      amountOfFeed: amountOfFeed,
      weeklyAverageGrowthRate: weeklyAverageGrowthRate,
      checkNeededCount: checkNeededCount,
      lethalCount: lethalCount,
      nonLethalCount: nonLethalCount,
      graphData: { forXAxis: forXAxis, forYAxis: forYAxis },
      averageSize: averageSize,
      sirvivalRate: sirvivalRate,
      stockingDensity: stockingDensity
    }
    setIsLoader(true);
    navigation("/counting_report", {
      state: {
        reportData: reportData
      }
    });
  };

  const onClickExportTest = async () => {
    generatePondReport()
  };

  const onClickExportAllTest = async () => {
    generateAllPondReport()
  };

  const onClickExport = async (isExportAll) => {
    setIsDisable(true);
    try {
      let newArr = [];
      let data = { id: sortCycle, type: monitoringType };
      if (isExportAll && startDate === "" && endDate === "") {
        newArr = await getPondRecordAllCycleById(data);
      } else if (isExportAll === false) {
        if (startDate === "") {
          setShowError({ ...showError, isFrom: true });
          setIsDisable(false);
          return;
        } else if (endDate === "") {
          setShowError({ ...showError, isTo: true });
          setIsDisable(false);
          return;
        }
        let newData = {
          ...data,
          startDate: getTimestampWithFormatDate(startDate,"x"),
          endDate: getTimestampWithFormatDate(endDate,"x"),
        };
        newArr = await getPondRecordCycleById(newData);
      }
      let createArrData = [];
      if (newArr.length > 0) {
        for (let i = 0; i < newArr.length; i++) {
          let obj = {}
          if (monitoringType == "HEALTH") {
            obj = {
              "Record Date": getTimestampWithFormatDate(newArr[i].recordDate,"DD-MM-YYYY @ hh:mma"),
              "Pond Name": newArr[i].pondName,
              "Shrimp Sick Status": newArr[i].shrimpSickStatus,
              Lethal: newArr[i].isLethal ? "Yes" : "No",
              Comment: newArr[i].comment,
              Status: newArr[i].status,
              "Reported User": newArr[i].reportedUser,
              TopImage: newArr[i].topImage,
              SideImage: newArr[i].sideImage,
            };
          } else {
            obj = {
              "Record Date": getTimestampWithFormatDate(newArr[i].recordDate,"DD-MM-YYYY @ hh:mma"),
              "Pond Name": newArr[i].pondName,
              "Shrimp amount": newArr[i].shrimpAmount,
              "Shrimp Weight": newArr[i].shrimpWeight,
              Comment: newArr[i].comment,
              "Reported User": newArr[i].reportedUser,
              Image: newArr[i].topImage,
            };
          }
          createArrData.push(obj);
        }
      }

      if (newArr.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(createArrData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "SincereAquacultureSheet.xlsx");
      } else {
        if (isExportAll) {
          if (newArr.length > 0) {
            alert(
              'Clear start or end date and then click "Export All" button you get data.'
            );
          } else {

            alert("No records found!");
          }
        } else {
          alert("No records found!");
        }
      }
      setIsDisable(false);
    } catch (error) {

      setIsDisable(false);
    }
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("report.reports")}
      isHideToolBar={true}
    >
      {isLoader ? (
        <div>
          {pondList.length > 0 ? (
            <div className="repot-main-block-css">
              {/* <div className="reports_filter_tab">
                <ul>
                  <li className={reportsFilter === "export_csv" ? "active" : ""}>
                    <a href="#0" onClick={() => {
                      setReportsFilter(getpath === "0" ? "export_csv" : "")
                    }
                    }>{t("report.export_csv")}</a>
                  </li>
                  <li className={reportsFilter === "generate_report" ? "active" : ""}>
                    <a href="#0"
                      onClick={() => {
                        setReportsFilter(getpath === "1" ? "generate_report" : "")
                      }}>{t("report.generate_report")}</a>
                  </li>
                </ul>
              </div> */}
              {/* {reportsFilter === "export_csv" ? (
                <>
                  <div className="export_all_button">
                    <Tooltip title="Export All" placement="top-start">
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: 5,
                          width: 180,
                          backgroundColor: colors.slatery_blue,
                          fontWeight: "600",
                          fontSize: 16,
                          color: "white",
                          textTransform: "capitalize",
                          height: 40,
                        }}
                        disabled={isExportAllDisable}
                        onClick={() => onClickExport(true)}
                      >
                        {t("report.export_all")}
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="repot-input-box">
                    <div className="input-block"> */}
              {/* ----- POND ----- */}
              {/* <div>
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("report.select_pond")}&nbsp;
                        </Typography>
                        <Box
                          sx={{
                            marginLeft: 1.3,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-pond"
                              id="demo-simple-pond"
                              value={sortPond}
                              //   label="Age"
                              onChange={async (e) => {
                                try {
                                  setCycleList([]);
                                  setSortCycle("");
                                  setSelectedCycleData(null);
                                  setSortPond(e.target.value);
                                  let data = pondList.filter((ele) => {
                                    return ele.id === e.target.value;
                                  });
                                  setSelectedPondData(data[0]);
                                  setPondName(data[0].pondName);
                                  let cycles = await getPondCycle(e.target.value);
                                  if (cycles.length > 0) {
                                    cycles.sort((a, b) => {
                                      return a.cycleNo - b.cycleNo;
                                    });
                                    setCycleList(cycles);
                                    setSortCycle(cycles[0].id);
                                    setSelectedCycleData(cycles[0]);
                                  } else {
                                    setIsCycleData(true);
                                  }
                                } catch (error) {

                                }
                              }}
                              input={<BootstrapInput />}
                            >
                              {pondList.length > 0 &&
                                pondList.map((val) => {
                                  if (val.pondName) {
                                    return (
                                      <MenuItem key={val.id} value={val.id}>
                                        {val.pondName}
                                      </MenuItem>
                                    );
                                  }
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div> */}

              {/* ----- CYCLE ----- */}
              {/* {cycleList.length > 0 ? (
                        <div>
                          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                            {t("report.select_cycle")}&nbsp;
                          </Typography>
                          <Box
                            sx={{
                              marginLeft: 1.3,
                            }}
                          >
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-cycle"
                                id="demo-simple-cycle"
                                value={sortCycle}
                                //   label="Age"
                                onChange={(e) => {
                                  setSortCycle(e.target.value)
                                  let data = cycleList.filter((ele) => {
                                    return ele.id === e.target.value;
                                  });
                                  setSelectedCycleData(data[0]);
                                }}
                                input={<BootstrapInput />}
                              >
                                {cycleList.length > 0 &&
                                  cycleList.map((val) => {
                                    return (
                                      <MenuItem key={val.id} value={val.id}>
                                        {val.cycleName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      ) : isCycleData ? (
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                            {t("report.no_cycle_found_in_pond")} "{pondName}"
                          </Typography>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            padding: "18px 0",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div id={`load`} className="loader" />
                        </div>
                      )} */}

              {/* ----- MONITORING TYPE ----- */}
              {/* {cycleList.length > 0 && (<div>
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("ponds.monitoringType")}&nbsp;
                        </Typography>
                        <Box
                          sx={{
                            marginLeft: 1.3,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-pond"
                              id="demo-simple-pond"
                              value={monitoringType}
                              label={t("ponds.healthMonitoring")}
                              onChange={(e) => setMonitoringType(e.target.value)}
                              input={<BootstrapInput />}
                            >
                              <MenuItem value={"HEALTH"}>
                                {t("ponds.healthMonitoring")}
                              </MenuItem>
                              <MenuItem value={"WEIGHT"}>
                                {t("ponds.weightMonitoring")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>)} */}

              {/* ----- START DATE ----- */}
              {/* {cycleList.length > 0 && (
                        <div className="from-input-data-css">
                          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                            {t("report.from_date")}&nbsp;
                          </Typography>
                          <input
                            id="startDateId"
                            type="date"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                              setShowError({ ...showError, isFrom: false });
                            }}
                            style={{
                              height: 41,
                              border: "1px solid lightgray",
                              paddingLeft: 10,
                              borderRadius: 4,
                              marginLeft: 10,
                              paddingRight: 8,
                              position: "relative",
                            }}
                          />
                        </div>
                      )}
                      {showError.isFrom && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <Alert
                            severity="error"
                            style={{
                              width: 390,
                            }}
                          >
                            {t("report.please_select_start_date")}
                          </Alert>
                        </div>
                      )} */}

              {/* ----- END DATE ----- */}
              {/* {cycleList.length > 0 && (
                        <div className="from-input-data-css">
                          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                            {t("report.to_date")}&nbsp;
                          </Typography>
                          <input
                            id="endDateId"
                            type="date"
                            value={endDate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                              setShowError({ ...showError, isTo: false });
                            }}
                            style={{
                              marginTop: 3,
                              height: 41,
                              border: "1px solid lightgray",
                              paddingLeft: 10,
                              borderRadius: 4,
                              marginLeft: 14,
                              paddingRight: 8,
                              position: "relative",
                            }}
                          />
                        </div>
                      )}
                      {showError.isTo && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <Alert
                            severity="error"
                            style={{
                              width: 390,
                              //   window.innerWidth > 375
                              //     ? window.innerWidth > 400
                              //       ? 400
                              //       : 300
                              //     : 260,
                              // marginLeft: 97,
                            }}
                          >
                            {t("report.please_select_end_date")}
                          </Alert>
                        </div>
                      )}
                    </div> */}
              {/* ----- EXPORT BUTTON ----- */}
              {/* <div className="button-block">
                      {cycleList.length > 0 && (
                        <div
                          style={{ flexDirection: 'column' }}>
                          <div className="report-button-css-block">
                            <Button
                              variant="contained"
                              sx={{
                                marginTop: 5,
                                width: 180,
                                backgroundColor: colors.slatery_blue,
                                fontWeight: "600",
                                fontSize: 16,
                                color: "white",
                                textTransform: "capitalize",
                                height: 40,
                              }}
                              disabled={isDisable}
                              onClick={() => onClickExport(false)}
                            >
                              {t("report.export_reports")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : ( */}
              <>
                <div className="all_ponds_list">
                  <ul>
                    <li className={isGenerateReportTab === "generate_report" ? "active" : ""}
                      onClick={() => {
                        setCurrentUser({...currentUser, "pondActive" : "0" })
                        let getpath = currentUser?.pondActive
                        setIsGenerateReportTab(getpath === "0" ? "generate_report" : "")
                      }}
                    >
                      <a href="#0">{t("report.all_ponds")}</a>
                    </li>
                    <li className={isGenerateReportTab === "Selected_pond" ? "active" : ""}
                      onClick={() => {
                        setCurrentUser({...currentUser, "pondActive" : "1" })
                        let getpath = currentUser?.pondActive
                        setIsGenerateReportTab(getpath === "1" ? "Selected_pond" : "")
                      }}>
                      <a href="#0" >{t("report.selected_pond")}</a>
                    </li>
                    <li className={isGenerateReportTab === "multiple_cycle_report" ? "active" : ""}
                      onClick={() => {
                        setCurrentUser({...currentUser, "pondActive" : "3" })
                        let getpath = currentUser?.pondActive
                        setIsGenerateReportTab(getpath === "3" ? "multiple_cycle_report" : "")
                      }}>
                      <a href="#0" >{t("report.multiple_cycle_report")}</a>
                    </li>
                  </ul>
                </div>
                {isGenerateReportTab === "generate_report" ? (
                  <div className="repot-input-box">
                    <div className="input-block">
                      <div className="multi_select_block">
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("report.select_one_or_more_pond")}&nbsp;
                        </Typography>
                        <div>
                          <Multiselect
                            options={pondList}
                            selectedValues={pondListSelected}
                            selectionLimit={10}
                            onSelect={handleSelect}
                            onRemove={handleRemove}
                            displayValue="pondName"
                          />
                        </div>
                      </div>
                      {/* ----- POND ----- */}
                      {/* <div>
                      <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                        {t("report.select_pond")}&nbsp;
                      </Typography>
                      <Box
                        sx={{
                          marginLeft: 1.3,
                        }}
                      >
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-pond"
                            id="demo-simple-pond"
                            value={sortPond}
                            //   label="Age"
                            onChange={async (e) => {
                              try {
                                setCycleList([]);
                                setSortCycle("");
                                setSelectedCycleData(null);
                                setSortPond(e.target.value);
                                let data = pondList.filter((ele) => {
                                  return ele.id === e.target.value;
                                });
                                setSelectedPondData(data[0]);
                                setPondName(data[0].pondName);
                                let cycles = await getPondCycle(e.target.value);
                                if (cycles.length > 0) {
                                  cycles.sort((a, b) => {
                                    return a.cycleNo - b.cycleNo;
                                  });
                                  setCycleList(cycles);
                                  setSortCycle(cycles[0].id);
                                  setSelectedCycleData(cycles[0]);
                                } else {
                                  setIsCycleData(true);
                                }
                              } catch (error) {

                              }
                            }}
                            input={<BootstrapInput />}
                          >
                            {pondList.length > 0 &&
                              pondList.map((val) => {
                                if (val.pondName) {
                                  return (
                                    <MenuItem key={val.id} value={val.id}>
                                      {val.pondName}
                                    </MenuItem>
                                  );
                                }
                              })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div> */}

                      {/* ----- CYCLE ----- */}
                      {/* {cycleList.length > 0 ? (
                          <div>
                            <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                              {t("report.select_cycle")}&nbsp;
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: 1.3,
                              }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-cycle"
                                  id="demo-simple-cycle"
                                  value={sortCycle}
                                  //   label="Age"
                                  onChange={(e) => {
                                    setSortCycle(e.target.value)
                                    let data = cycleList.filter((ele) => {
                                      return ele.id === e.target.value;
                                    });
                                    setSelectedCycleData(data[0]);
                                  }}
                                  input={<BootstrapInput />}
                                >
                                  {cycleList.length > 0 &&
                                    cycleList.map((val) => {
                                      return (
                                        <MenuItem key={val.id} value={val.id}>
                                          {val.cycleName}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        ) : isCycleData ? (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                              {t("report.no_cycle_found_in_pond")} "{pondName}"
                            </Typography>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              padding: "18px 0",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <div id={`load`} className="loader" />
                          </div>
                        )} */}
                    </div>
                    {/* ----- EXPORT BUTTON ----- */}
                    <div className="button-block">
                      {cycleList.length > 0 && (
                        <div
                          style={{ flexDirection: 'column' }}>
                          <div className="report-button-css-block button_two">
                            <Button
                              variant="contained"
                              sx={{
                                marginTop: 5,
                                width: 180,
                                backgroundColor: colors.slatery_blue,
                                fontWeight: "600",
                                fontSize: 16,
                                color: "white",
                                textTransform: "capitalize",
                                height: 40,
                              }}
                              disabled={isExportAllDisable}
                              onClick={() => generateAllPondReport()}
                            >
                              {t("report.generate")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {isGenerateReportTab === "Selected_pond" ? (
                  <div className="repot-input-box">
                    <div className="input-block">
                      {/* <div className="multi_select_block">
                          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                            {t("report.select_pond")}&nbsp;
                          </Typography>
                          <div>
                            <Multiselect
                              options={pondList}
                              selectedValues={pondListSelected}
                              selectionLimit={10}
                              onSelect={handleSelect}
                              onRemove={handleRemove}
                              displayValue="pondName"
                            />
                          </div>
                        </div> */}
                      {/* ----- POND ----- */}
                      <div>
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("report.select_pond")}&nbsp;
                        </Typography>
                        <Box
                          sx={{
                            marginLeft: 1.3,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-pond"
                              id="demo-simple-pond"
                              value={sortPond}
                              //   label="Age"
                              onChange={async (e) => {
                                try {
                                  setCycleList([]);
                                  setSortCycle("");
                                  setSelectedCycleData(null);
                                  setSortPond(e.target.value);
                                  let data = pondList.filter((ele) => {
                                    return ele.id === e.target.value;
                                  });
                                  setSelectedPondData(data[0]);
                                  setPondName(data[0].pondName);
                                  let cycles = await getPondCycle(e.target.value,currentUser.pId);
                                  if (cycles.length > 0) {
                                    cycles.sort((a, b) => {
                                      return a.cycleNo - b.cycleNo;
                                    });
                                    setCycleList(cycles);
                                    setSortCycle(cycles[0].id);
                                    setSelectedCycleData(cycles[0]);
                                  } else {
                                    setIsCycleData(true);
                                  }
                                } catch (error) {
                                  console.log('error: ', error);
                                }
                              }}
                              input={<BootstrapInput />}
                            >
                              {pondList.length > 0 &&
                                pondList.map((val) => {
                                  if (val.pondName) {
                                    return (
                                      <MenuItem key={val.id} value={val.id}>
                                        {val.pondName}
                                      </MenuItem>
                                    );
                                  }
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      {/* ----- CYCLE ----- */}
                      {cycleList.length > 0 ? (
                        <div>
                          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                            {t("report.select_cycle")}&nbsp;
                          </Typography>
                          <Box
                            sx={{
                              marginLeft: 1.3,
                            }}
                          >
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-cycle"
                                id="demo-simple-cycle"
                                value={sortCycle}
                                //   label="Age"
                                onChange={(e) => {
                                  setSortCycle(e.target.value)
                                  let data = cycleList.filter((ele) => {
                                    return ele.id === e.target.value;
                                  });
                                  setSelectedCycleData(data[0]);
                                }}
                                input={<BootstrapInput />}
                              >
                                {cycleList.length > 0 &&
                                  cycleList.map((val) => {
                                    return (
                                      <MenuItem key={val.id} value={val.id}>
                                        {val.cycleName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      ) : isCycleData ? (
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                            {t("report.no_cycle_found_in_pond")} "{pondName}"
                          </Typography>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            padding: "18px 0",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div id={`load`} className="loader" />
                        </div>
                      )}
                    </div>
                    {/* ----- EXPORT BUTTON ----- */}
                    <div className="button-block">
                      {cycleList.length > 0 && (
                        <div
                          style={{ flexDirection: 'column' }}>
                          <div className="report-button-css-block button_two">
                            <Button
                              variant="contained"
                              sx={{
                                marginTop: 5,
                                width: 180,
                                backgroundColor: colors.slatery_blue,
                                fontWeight: "600",
                                fontSize: 16,
                                color: "white",
                                textTransform: "capitalize",
                                height: 40,
                              }}
                              disabled={isDisable}
                              onClick={() => generatePondReport()}
                            >
                              {t("report.generate")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {isGenerateReportTab === "multiple_cycle_report" ? (
                  <div className="repot-input-box">
                    <div className="input-block">
                      {/* ----- POND ----- */}
                      <div>
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("report.select_pond")}&nbsp;
                        </Typography>
                        <Box
                          sx={{
                            marginLeft: 1.3,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-pond"
                              id="demo-simple-pond"
                              value={sortPond}
                              //   label="Age"
                              onChange={async (e) => {
                                try {
                                  setCycleList([]);
                                  setSortCycle("");
                                  setSelectedCycleData(null);
                                  setSortPond(e.target.value);
                                  let data = pondList.filter((ele) => {
                                    return ele.id === e.target.value;
                                  });
                                  setPondListId(data[0].id);
                                  setSelectedPondData(data[0]);
                                  setPondName(data[0].pondName);
                                  let cycles = await getPondCycle(e.target.value,currentUser.pId);
                                  if (cycles.length > 0) {
                                    cycles.sort((a, b) => {
                                      return a.cycleNo - b.cycleNo;
                                    });
                                    setCycleList(cycles);
                                    setSortCycle(cycles[0].id);
                                    setSelectedCycleData(cycles[0]);
                                  } else {
                                    setIsCycleData(true);
                                  }
                                } catch (error) {
                                  console.log(error, "error");
                                }
                              }}
                              input={<BootstrapInput />}
                            >
                              {pondList.length > 0 &&
                                pondList.map((val) => {
                                  if (val.pondName) {
                                    return (
                                      <MenuItem key={val.id} value={val.id}>
                                        {val.pondName}
                                      </MenuItem>
                                    );
                                  }
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      {/* ----- multi_select ----- */}
                      <div className="multi_select_block">
                        <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                          {t("report.select_one_or_more_cycle")}&nbsp;
                        </Typography>
                        <div>
                          <Multiselect
                            options={pondInActiveCycle}
                            selectedValues={InActiveCycleSelected}
                            selectionLimit={10}
                            onSelect={handleSelect}
                            onRemove={handleRemove}
                            displayValue="cycleName"
                            emptyRecordMsg={t("ReportAll.no_active_cycle")}
                          />
                        </div>
                      </div>
                      {/* ----- CYCLE ----- */}
                      {/* {cycleList.length > 0 ? (
                          <div>
                            <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                              {t("report.select_cycle")}&nbsp;
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: 1.3,
                              }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-cycle"
                                  id="demo-simple-cycle"
                                  value={sortCycle}
                                  //   label="Age"
                                  onChange={(e) => {
                                    setSortCycle(e.target.value)
                                    let data = cycleList.filter((ele) => {
                                      return ele.id === e.target.value;
                                    });
                                    setSelectedCycleData(data[0]);
                                  }}
                                  input={<BootstrapInput />}
                                >
                                  {cycleList.length > 0 &&
                                    cycleList.map((val) => {
                                      return (
                                        <MenuItem key={val.id} value={val.id}>
                                          {val.cycleName}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        ) : isCycleData ? (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                              {t("report.no_cycle_found_in_pond")} "{pondName}"
                            </Typography>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              padding: "18px 0",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <div id={`load`} className="loader" />
                          </div>
                        )} */}
                    </div>
                    {/* ----- EXPORT BUTTON ----- */}
                    <div className="button-block">
                      {cycleList.length > 0 && (
                        <div
                          style={{ flexDirection: 'column' }}>
                          <div className="report-button-css-block button_two">
                            <Button
                              variant="contained"
                              sx={{
                                marginTop: 5,
                                width: 180,
                                backgroundColor: colors.slatery_blue,
                                fontWeight: "600",
                                fontSize: 16,
                                color: "white",
                                textTransform: "capitalize",
                                height: 40,
                              }}
                              disabled={isExportAllDisable}
                              onClick={() => generateAllPondCycleReport()}
                            >
                              {t("report.generate")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
              {/* )} */}
            </div>
          ) : isPondData ? (
            <div
              style={{
                display: "flex",
                height: "80vh",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                {t("report.report_not_found")}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                height: "80vh",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div id={`load`} className="loader" />
            </div>
          )}
        </div>
      ) : (
        <div
          className="link_loafer_block"
          style={{
            display: "flex",
            height: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )
      }
    </ResponsiveDrawer>
  );
}

export default Report;
