import { onAuthStateChanged } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from "./config/firebase-config";
import { getRoleByUid } from './database/auth/login';

const HomeRoute = () => {
    const [role, setRole] = useState('')
    const [loading, setLoading] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate();

    const onLoad = useCallback(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user && user.uid) {
                const userRole = await getRoleByUid(user.uid)
                setRole(userRole)
                setIsLoggedIn(true)
                setLoading(false)
            } else {
                setIsLoggedIn(false)
                setLoading(false)
            }
        })
    }, [navigate])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    if (loading) {
        return <div className="four_zero_four_image_container" style={{ marginTop: 15 }}>
            <div id={`load`} className="loader" />
        </div>
    }

    if (isLoggedIn)
        <Navigate to={"/login"} />

    if (role === "superAdmin")
        return (
            <Navigate to={"/superadmin/users"} />
        )
    else
        return (
            <Navigate to={"/home"} />
        )
}

export default HomeRoute
