export const FIRESTORE_BATCH = "batch";
export const FIRESTORE_MEASUREMENT_REPORTS = "sizeReports";
export const FIRESTORE_COUNTING_REPORTS = "countReports";
export const FIRESTORE_COUNTING_RECORDS = "countRecords";
export const FIRESTORE_COUNTING_RECORDS_60K = "countRecords60K";
export const FIRESTORE_COUNTING_RECORDS_SC_HATCHERY = "countRecordsSCHatchery";
export const FIRESTORE_COUNTING_RECORDS_20K = "countRecords20K";
export const FIRESTORE_POND_CYCLE = "pondCycle";
export const FIRESTORE_DEVICE_POOL = "devicePool";
export const FIRESTORE_LICENSES = "licenses";
export const FIRESTORE_USERS = "Users";
export const FIRESTORE_COUNTERS = "counters";
export const FIRESTORE_PONDS = "Ponds";
export const FIRESTORE_ALARMS = "alarms";
export const FIRESTORE_DEVICES = "devices";
export const FIRESTORE_FARMS = "farms";
export const FIRESTORE_INVOICES = "invoices";
export const FIRESTORE_POND_RECORD = "pondRecord";
export const FIRESTORE_TRANSFER_HISTORY = "transferHistory";
export const FIRESTORE_PLANS = "plans"; 

// Fiunction name
export const UPDATE_USER_PASSWORD_FUNCTION = "updateUserPassword"; 
export const FIREBASE_SET_USERS_VERIFY_ON_SIGNUP_FUNCTION = "setUsersVerifyOnSignUp"; 
export const FIREBASE_EMAIL_MAILGUN_FUNCTION = "sendEmailMailGun"; 
export const FIREBASE_CREATE_USER_FUNCTION = "createUser"; 

export const MAIL_TYPE_EMAIL_VERIFICATION = 'emailVerification'
export const MAIL_TYPE_SEND_INVITE_LINK = 'sendinvitelink'
export const TO_POND_ID = "toPondId";
export const TO_CYCLE_ID = "toCycleId";
export const FROM_CYCLE_ID = "fromCycleId";
export const FROM_POND_ID = "fromPondId";
export const POND_ID = "pondId";
export const CYCLE_ID = "cycleId";
export const DEVICE_MODE_COUNTING = "c";
export const DEVICE_MODE_MEASUREMENT = "m";
export const CYCLE_ACTIVE = "Active";
export const POND_LIST = "pondList";
export const RECORD_DATE = "recordDate";
export const NO_DATA = "No Data";
export const CURRENT_CYCLE_ID = "currentCycleId";
export const ID = "id";
export const PARENT_ID = "pId";
export const STATUS_ACTIVE = "Active";
export const STATUS = "status";
export const HEALTH_CONDITION = "healthCondition";
export const FREE = "free";
export const NUMBER_OF_USERS_UNLIMITED = "unlimited";
export const TYPE = "type";
export const COUNTER1 = "counter1";
export const ALL_FARMS = "All farms";

//FIREBASE AUTH ERROR CODES
export const AUTH_WRONG_PASSWORD = "auth/wrong-password";
export const AUTH_USER_NOT_FOUND = "auth/user-not-found";
export const AUTH_INVALID_EMAIL = "auth/invalid-email";
export const AUTH_EMAIL_DISABLED = "auth/user-disabled";
export const AUTH_TOO_MANY_REQUESTS = "auth/too-many-requests";
export const AUTH_OPERATION_NOT_ALLOWED = "auth/operation-not-allowed";
export const AUTH_NETWORK_REQUEST_FAILED = "auth/network-request-failed";
export const AUTH_REQUIRES_RECENT_LOGIN = "auth/requires-recent-login";
