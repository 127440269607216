import {
  Box,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Modal,
  Select,
  Typography,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import DeviceList from "../../../components/device/DeviceList";
import NativeSelect from "@mui/material/NativeSelect";
import {
  addDevice,
  getDevices,
  deleteDevice,
  getDeviceById,
  getUserData,
} from "../../../database/user/pond";
import {
  DEVICE_SC_4K,
  DEVICE_SC_20K,
  DEVICE_SP_1,
  DEVICE_SC_60K,
  DEVICE_SC_HATCHERY,
} from "../../../common/configs";
import { Error } from "../../../common/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import DeviceListPool from "../../../components/device/DeviceListPool";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function DevicesPool() {
  const { t } = useTranslation();

  const YES = "yes";
  const NO = "no";

  const [deviceList, setDeviceList] = useState([]);
  const [deviceList2, setDeviceList2] = useState([]);
  const [noOfDevices, setNoOfDevices] = useState(0);
  const [sortValue, setSortValue] = useState(1);
  const [sortBackUpDevice, setSortBackUpDevice] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [deviceIdVal, setDeviceIdVal] = useState("");
  const [deviceIdError, setDeviceIdError] = useState("");
  const [isBackUpDevice, setbackUpDevice] = useState(NO);
  const [device, setDevice] = useState(DEVICE_SC_4K);
  const [isRefresh, setRefresh] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const addDeviceIdDB = async () => {
    setIsDeviceModal(false);
    let data = {
      deviceId: deviceIdVal,
      type: device,
      isActive: false,
      isBackUpDevice: isBackUpDevice == YES,
    };
    setLoading(true);
    const deviceLocal = await getDeviceById(deviceIdVal);
    if (deviceLocal && deviceLocal.deviceId == deviceIdVal) {
      Error(t("device.alreadyRegistered"));
      setRefresh(false);
      setDeviceIdVal("");
      setLoading(false);
      return;
    }
    const addId = await addDevice(data, deviceIdVal);
    setRefresh(true);
    setDeviceIdVal("");
    setLoading(false);
  };

  const onClickDevice = () => {
    if (deviceIdVal == "" || device == "") {
      setDeviceIdError(t("device.please_fill_the_dev_id"));
    } else {
      addDeviceIdDB();
    }
  };

  const onChangeDeviceIdVal = (e) => {
    e.preventDefault();
    setDeviceIdVal(e.target.value);
    setDeviceIdError("");
  };

  const onChangeBackUp = (event) => {
    setbackUpDevice(event.target.value);
  };

  const onChangeDevice = (event) => {
    setDevice(event.target.value);
  };

  useEffect(() => {
    if (deviceList2.length > 0) {
      setTimeout(() => {
        setSearch(searchText);
      }, 1000);
    }
  }, [searchText]);

  useEffect(() => {
    searchId();
  }, [search, sortValue,sortBackUpDevice]);

  const searchId = () => {
    let mainData = deviceList2;
    if (search) {
      mainData = deviceList2.filter((val) => {
        return val.deviceId.toLowerCase().includes(search.toLowerCase());
      });
    }
    if (sortValue == 2) {
      mainData = mainData.filter((ele) => {
        return ele.pId;
      });
    } else if (sortValue == 3) {
      mainData = mainData.filter((ele) => {
        return !ele.pId;
      });
    }
    if (sortBackUpDevice == 2) {
      mainData = mainData.filter((ele) => {
        return ele.isBackUpDevice;
      });
    } else if (sortBackUpDevice == 3) {
      mainData = mainData.filter((ele) => {
        return !ele.isBackUpDevice;
      });
    }
    setDeviceList(mainData);
  };

  useEffect(() => {
    if (isRefresh) {
      getDevicesListData();
      setRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getDevicesListData();
  }, []);

  async function getDevicesListData() {
    try {
      let data = await getDevices();
      setNoOfDevices(data.length);
      setDeviceList(data);
      setDeviceList2(data);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("device.devices_pool")}
      isHideToolBar={true}
    >
      <div
        className="column-block-css detail-css-block"
        style={{ marginTop: -20 }}
      >
        {/* ----- HEAD SEARCHES ----- */}
        <div className="head_container head_devices hedera_ui_css_block">
          <div className="hedera_flex_block_row">
            <div className="head_two select-main-block">
              <Box sx={{ minWidth: 260 }}>
                <Typography>{t("device.filter_by_status")}</Typography>
                <FormControl>
                  <Select
                    className="Select-bg-color"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortValue}
                    onChange={async (e) => {
                      e.stopPropagation();
                      setSortValue(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={1}>{t("device.all")}</MenuItem>
                    <MenuItem value={2}>{t("device.consumed")}</MenuItem>
                    <MenuItem value={3}>{t("device.available")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="head_two select-main-block">
              <Box sx={{ minWidth: 260 }}>
                <Typography>{t("licenseDetails.filter_by_backUp_device")}</Typography>
                <FormControl>
                  <Select
                    className="Select-bg-color"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortBackUpDevice}
                    onChange={async (e) => {
                      e.stopPropagation();
                      setSortBackUpDevice(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={1}>{t("device.all")}</MenuItem>
                    <MenuItem value={2}>{t("licenseDetails.backup_device")}</MenuItem>
                    <MenuItem value={3}>{t("licenseDetails.normal_device")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="head_one">
              <div
                style={{ width: "100%", marginTop: 0, marginLeft: 0 }}
                className="ui stackable two column grid"
              >
                <div
                  className="column"
                  style={{ width: "260px", padding: "0" }}
                >
                  <div
                    className="ui icon input input-css-block"
                    style={{ width: "260px" }}
                  >
                    <input
                      className="prompt"
                      type="text"
                      placeholder={t("ponds.search")}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setSearchText(e.target.value);
                        } else {
                          setSearchText(e.target.value);
                        }
                      }}
                      value={searchText}
                      style={{ border: "1px solid #ced4da", height: 43 }}
                    />
                    <i className="search icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="ui primary button"
            style={{ width: 120, marginLeft: 5 }}
            onClick={() => {
              setIsDeviceModal(true);
            }}
          >
            {t("ponds.add")}
          </button>
        </div>
        {/* ----- DEVICES LIST TABLE ----- */}
        <div className="cards_main_block">
          <div className="inventory_table_wrap">
            <div className="table-responsive cm_table_responsive">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div id={`load`} className="loader" />
                </div>
              ) : deviceList.length > 0 ? (
                <table className="cm_table fold-table">
                  <thead className="cm_thead">
                    <tr className="cm_thead_list">
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("device.device_id")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("device.type")}</h6>
                        </div>
                      </th>
                      {/* <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("home.condition")}</h6>
                        </div>
                      </th> */}
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("home.status")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("device.owner")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.backUp_device")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name"></th>
                    </tr>
                  </thead>
                  <tbody className="cm_tbody">
                    {deviceList.map((val, index) => {
                      return (
                        <>
                          <tr key={Math.random()} className="cm_tr view">
                            <DeviceListPool
                              isDevPool={true}
                              val={val}
                              onClickCard={() => {}}
                              onClickMenu={() => {
                                setDeviceId(
                                  val.id === deviceId ? null : val.id
                                );
                              }}
                              openId={deviceId}
                              onClickDelete={() => {
                                setDeviceId(val.deviceId);
                                setIsDeleteModal(true);
                              }}
                            />
                          </tr>
                          <tr className="cm_hi"></tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                    {t("device.device_not_found")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* ----- DELETE MODAL ----- */}
        <Modal
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[boxStyle, { width: 400 }]}
            className="logout-css-modal-block"
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                paddingTop: 2,
                textAlign: "center",
                marginBottom: 2,
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {t("ponds.are_you_sure_you_want_to_delete_this")}
            </Typography>

            <Divider />
            <div className="logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setDeviceId(null);
                    setIsDeleteModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <div
                style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
              />
              <Typography
                className="delete_bg_color"
                sx={[textStyle, { color: "red" }]}
                onClick={async () => {
                  if (isDisable) {
                  } else {
                    try {
                      setLoading(true);
                      setIsDeleteModal(false);
                      await deleteDevice(deviceId);
                      setRefresh(true);
                      setLoading(false);
                    } catch (error) {
                      setIsDisable(false);
                    }
                  }
                }}
              >
                {t("ponds.delete")}
              </Typography>
            </div>
          </Box>
        </Modal>

        {/* ------ ADD MODAL ------ */}
        <Modal
          open={isDeviceModal}
          onClose={() => {
            setIsDeviceModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle} className="logout-css-modal-block">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ paddingTop: 2, textAlign: "center", fontWeight: "600" }}
            >
              {t("ponds.add") + " " + t("device.devices")}
            </Typography>
            <div className="add_parameters_input">
              <label>{t("device.device_id")}</label>
              <input
                type="text"
                placeholder={t("ponds.enter") + " " + t("device.device_id")}
                name="device_id"
                value={deviceIdVal}
                onChange={(e) => onChangeDeviceIdVal(e)}
              />
              {deviceIdError && <Alert severity="error">{deviceIdError}</Alert>}
            </div>
            {/* <div>
              <Typography sx={{ fontSize: 15, fontWeight: "600" }}>
                {t("device.type")}&nbsp;
              </Typography>
              <Box
                style={{
                  marginTop: 2,
                }}
              >
                <FormControl fullWidth>
                  <NativeSelect
                    labelId="demo-simple-select-cycle"
                    id="demo-simple-cycle"
                    value={deviceTypeVal}
                    onChange={onChangeDeviceVal}
                    input={<BootstrapInput />}
                  >
                    <option value={t("device.counter")}>
                      {t("device.counter")}
                    </option>
                    <option value={t("device.probe")}>
                      {t("device.probe")}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </div> */}
            <div style={{ marginTop: 15 }}>
              <Typography sx={{ fontSize: 15, fontWeight: "600" }}>
                {t("device.type")}&nbsp;
              </Typography>
              <Box
                style={{
                  marginTop: 2,
                }}
              >
                <FormControl fullWidth>
                  <NativeSelect
                    labelId="demo-simple-select-cycle"
                    id="demo-simple-cycle"
                    value={device}
                    onChange={onChangeDevice}
                    input={<BootstrapInput />}
                  >
                    <option value={DEVICE_SC_HATCHERY}>{t("device.sc-Hatchery")}</option>
                    <option value={DEVICE_SC_4K}>{t("device.sc_4k")}</option>
                    <option value={DEVICE_SC_20K}>{t("device.sc_20k")}</option>
                    <option value={DEVICE_SP_1}>{t("device.sp_1")}</option>
                    <option value={DEVICE_SC_60K}>{t("device.sc_60k")}</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 15 }}>
              <Typography sx={{ fontSize: 15, fontWeight: "600" }}>
                {t("device.backUpDeviceQ")}&nbsp;
              </Typography>
              <Box
                style={{
                  marginTop: 2,
                }}
              >
                <FormControl fullWidth>
                  <NativeSelect
                    labelId="demo-simple-select-cycle"
                    id="demo-simple-cycle"
                    value={isBackUpDevice}
                    onChange={onChangeBackUp}
                    input={<BootstrapInput />}
                  >
                    <option value={YES}>{t("ponds.yes")}</option>
                    <option value={NO}>{t("ponds.no")}</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </div>
            <div
              style={{ marginTop: 15 }}
              className="logout-button-block submit-logout-button-block cancel_outline"
            >
              <Typography
                sx={textStyle}
                onClick={() => {
                  setIsDeviceModal(false);
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <span></span>
              <button type="submit" onClick={onClickDevice}>
                {t("ponds.add")}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </ResponsiveDrawer>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default DevicesPool;
