import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import { useNavigate, useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  getTimestampWithFormatDate,
  isSingleDimensionWithoutObjects,
  isValidJSON,
  sumAllArray,
  sumUntilIndex,
} from "../../common/utils";
import { COUNTER1 } from "../../database/config";
import imagePath from "../../common/imagePath";
import html2pdf from "html2pdf.js";
import fullscreen from "../../images/fullscreen.png";
import zoomPlugin from "chartjs-plugin-zoom";
import * as XLSX from "xlsx";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  styled,
  InputBase,
  Typography,
} from "@mui/material";
import no_alarms_found from "../../images/no_alarms_found.png";
import { getMachineUpdatedAt } from "../../database/user/settings";
import { useAuth } from "../../common/AuthContext";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const tdStyle = {
  border: "1px solid black",
  padding: "8px",
};

const oddRowStyle = {
  backgroundColor: "#f2f2f2", // Light gray for odd rows
  tableLayout: "fixed",
  overflow: "hidden",
  width: "100%",
  display: "table",
  height: "fit-content",
};

const evenRowStyle = {
  backgroundColor: "#ffffff", // White for even rows
  tableLayout: "fixed",
  overflow: "hidden",
  width: "100%",
  display: "table",
  height: "fit-content",
};

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  zoomPlugin
);

const throuputArray = (array) => {
  // Iterate through the array and calculate the differences
  const differences = array.map((value, index) => {
    // Skip the first element since there's no previous element to subtract from
    if (index === 0) return value;

    // Calculate the difference between the current and the previous element
    return value - array[index - 1];
  });

  // Print the differences
  return differences.map((val, index) => {
    if (index === 0) {
      return array[index];
    } else {
      return array[index] - array[index - 1];
    }
  });
};
const device_version_3 = "3.00.20";
const CountingReport = () => {
  // const minuteInGraph = 1
  const navigation = useNavigate();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const exportTypeList = [
    {
      name: t("report.excel"),
      value: "excel",
    },
    {
      name: t("report.csv"),
      value: "csv",
    },
  ];
  const [minuteInGraph, setMinuteInGraph] = useState(1);
  const [exportType, setExportType] = useState("excel");
  const [totalDuration, setTotalDuration] = useState(0);
  const [barchartData, setBarchartData] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [totalCountChartData, setTotalCountChartData] = useState();
  const [totalCorrectRapport, setTotalCorrectRapport] = useState();
  const [lineCounterArray, setLineCounterArray] = useState([]);
  const [lineComments, setLineComments] = useState([]);
  const [device_version, setDeviceVersion] = useState("");
  const { recordData, pondData, cycleData } = location.state;
  const [isLoading, setLoading] = useState(true);
  const organization = currentUser?.comName;
  useEffect(() => {
    onLoad();
  }, [recordData.countersData]);

  const onLoad = async () => {
    if (recordData.countersData) {
      // const machineUpdatedAtRes = await getMachineUpdatedAt()
      const machineUpdatedAtRes = 1725820200;
      if (Number(recordData?.uploadedOn) > machineUpdatedAtRes) {
        setMinuteInGraph(1);
      } else {
        setMinuteInGraph(5);
      }
      const countersData = JSON.parse(recordData.countersData);
      correctDataRapport(countersData);
      let barChartDataTmp = [];
      let lstComments = [];
      setDeviceVersion(countersData.device_version);
      for (const key in countersData) {
        if (countersData.hasOwnProperty(key)) {
          countersData[key] =
            isValidJSON(countersData[key]) &&
            typeof countersData[key] === "string"
              ? JSON.parse(countersData[key])
              : countersData[key];
          const aa = isSingleDimensionWithoutObjects(countersData[key])
            ? countersData.device_version === device_version_3
              ? countersData[key]
              : countersData[key]
            : countersData[key];
          console.log("aa: ", aa);
          if (aa && aa.length > 0) {
            console.log("countersData[key]: ", countersData[key]);
            if (isSingleDimensionWithoutObjects(countersData[key])) {
              barChartDataTmp.push(aa);
            } else {
              if (typeof aa !== "string") {
                lstComments.push(aa);
              }
            }
          }

          
          const counterArray = Object.keys(countersData)
          .filter(key => key.startsWith("counter"))
          .map(key => countersData[key]);
          setLineCounterArray(counterArray);
          setLineComments(counterArray[counterArray.length - 1]);
          const newData = transformData(
            counterArray,
            countersData.device_version
          );
          setTotalCountChartData(newData);
        }
        if (key == COUNTER1) {
          setTotalDuration(countersData[key].length);
        }
      }
      const finalBarChartData = sumAllArray(...barChartDataTmp);

      console.log("lstComments.flat(): ", lstComments.flat());
      setCommentList(lstComments.flat());
      setBarchartData(finalBarChartData.slice(1));
    }
  };
  const transformData = (oldData, device_version) => {
    // Extract the numerical arrays from oldData
    const numericArrays = oldData.slice(0, 5);

    // Calculate the column-wise totals
    const columnTotals = numericArrays[0].map((_, colIndex) => {
      return numericArrays.reduce((sum, array) => {
        return sum + (array[colIndex] || 0);
      }, 0);
    });


    const cumulativeSums = columnTotals.reduce((acc, curr, index) => {
        acc.push((acc[index - 1] || 0) + curr);
         return acc;
        }, []);

    // Get the array of objects (assuming it's the last item in oldData)
    const arrayOfObjects = oldData[5];

    // Construct newData and update it in one step
    let newData = [];
    if (device_version === device_version_3) {
      const inputArray = cumulativeSums.slice(1);
      const outputArray = [...inputArray];

      for (let i = 0; i < inputArray.length; i++) {
        let sum = i == 0 ? outputArray[i] : outputArray[i - 1];
        if (i == 0) sum = sum;
        else if (i <= inputArray.length - 1) sum += inputArray[i]; // Add next value if exists
        outputArray[i] = sum;
      }
      newData = [
        outputArray, // Remove the value 0 from the first array
        arrayOfObjects,
      ];
    } else {
      newData = [
        cumulativeSums.slice(1), // Remove the value 0 from the first array
        arrayOfObjects,
      ];
    }
    return newData;
  };

  // const getLines = (counterData) => {
  //   const timeArray = [];
  //   for (let i = 1; i <= counterData.length; i++) {
  //     timeArray.push(i * 5);
  //   }
  //   const lineData = {
  //     labels: timeArray,
  //     datasets: [
  //       {
  //         label: t("ponds.average_count"),
  //         data: counterData,
  //         fill: false,
  //         backgroundColor: "rgba(238, 124, 67, 0.3)",
  //         borderColor: "rgb(238, 124, 67)",
  //         borderWidth: 1,
  //         pointRadius: 0,
  //         lineTension: 0, // Set lineTension to 0 for a zigzag line
  //         steppedLine: true, // Set steppedLine to true to create a zigzag line
  //       },
  //     ],
  //   };
  //   return lineData;
  // };

  // const getOptions = (key) => {
  //   return {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         // display: false,
  //         position: "top",
  //         labels: {
  //           padding: 0,
  //         },
  //       },
  //       title: {
  //         display: true,
  //         text: t(`report.${key}Average`),
  //         font: {
  //           size: 14,
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         grid: {
  //           display: false,
  //           barPercentage: 0.5,
  //           borderColor: "#fff",
  //         },
  //         ticks: {
  //           font: {
  //             size: 14,
  //             weight: 500,
  //             color: "red",
  //           },
  //         },
  //         gridLines: {
  //           borderDash: [8, 4],
  //           color: "#348632",
  //         },
  //       },
  //       y: {
  //         grid: {
  //           borderColor: "#fff",
  //           borderDash: [4, 7],
  //           color: "#bebebe",
  //         },
  //         ticks: {
  //           precision: 1,
  //           callback: (label) => `${label} `,
  //           font: {
  //             size: 14,
  //             weight: 500,
  //             color: "red",
  //           },
  //         },
  //       },
  //     },
  //   };
  // };

  const processData = (data) => {        
    const countsArray = data; // Excluding the comments array
    const modifiedArray = countsArray.map((subArray) => subArray ? subArray.slice(1) : []);
    const commentsArray = data?.length && data[data?.length - 1]; // Comments array
    const numMinutes = modifiedArray?.length ? modifiedArray[0]?.length : [];
    const labels = Array.from(
      { length: numMinutes },
      (_, index) => `${(index + 1) * minuteInGraph}`
    );
    const colorArray = [
      "#39b14c",
      "#fdf201",
      "#ed1d25",
      "#3aa2e8",
      "#a349a4",
      "#ff7f00",
      "#00bfff",
      "#8b4513",
      "#ff1493",
      "#32cd32",
      "#ffd700",
      "#6a5acd",
      "#c71585",
      "#40e0d0",
      "#8fbc8f",
    ];
    const datasets =
      (modifiedArray &&
        modifiedArray?.map((counts, index) => ({
          label: `${t("report.chamber")} ${index + 1}`,
          data: counts,
          borderColor: colorArray[index], // Just an example color
          backgroundColor: colorArray[index],
          pointRadius: 5, // Increased size for visibility
          pointHoverRadius: 7, // Larger hover radius
          tension: 0.3,
        }))) ||
      [];
    return { labels, datasets, comments: commentsArray };
  };

  const { labels, datasets, comments } = processData(lineCounterArray);
  const countingReportOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        mode: "nearest", // Ensures tooltips only show on individual points
        intersect: true,
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return `${t("report.minute")} ${index + 1}`;
          },
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            let label = `${dataset.label}: ${dataset.data[index]}`;
            return label;
          },
          // If still not working, you might use the `footer` callback to add content
          footer: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const dataset = tooltipItems[0].dataset;

            if (dataset.label === "Chamber 1" || dataset.label === "Cámara 1") {
              const minuteComments = Array.isArray(comments)
                ? comments
                    .filter((comment) => comment.count_minute === index + 1)
                    .map((comment) => comment.comment)
                    .join(", ")
                : "";
              if (minuteComments) {
                return [`${t("ponds.comment")}: ${minuteComments}`];
              }
            }
            return [];
          },
        },
        displayColors: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("report.time_minutes"),
        },
        // ticks: {
        //   callback: function (value) {
        //     return value;
        //   },
        // },
      },
      y: {
        title: {
          display: true,
          text: t("report.total_count_per_chamber"),
        },
        min: 0,
      },
    },
  };

  const processDataTotal = (data) => {
    const countsArray = data?.slice(0, -1); // Excluding the comments array
    const commentsArray = data?.length ? data[data?.length - 1] : []; // Comments array

    const numMinutes = countsArray?.length && countsArray[0]?.length;
    const labelsTotal = Array.from(
      { length: numMinutes },
      (_, index) => `${(index + 1) * minuteInGraph}`
    );
    const colorArray = [
      "#39b14c",
      "#fdf201",
      "#ed1d25",
      "#3aa2e8",
      "#a349a4",
      "#ff7f00",
      "#00bfff",
      "#8b4513",
      "#ff1493",
      "#32cd32",
      "#ffd700",
      "#6a5acd",
      "#c71585",
      "#40e0d0",
      "#8fbc8f",
    ];
    const datasetsTotal =
      (countsArray &&
        countsArray?.map((counts, index) => ({
          label: `${t("report.chamber")} ${index + 1}`,
          data: counts,
          borderColor: colorArray[index], // Just an example color
          backgroundColor: colorArray[index],
          pointRadius: 5, // Increased size for visibility
          pointHoverRadius: 7, // Larger hover radius
          tension: 0.3,
        }))) ||
      [];

    return { labelsTotal, datasetsTotal, commentsArrayTotal: commentsArray };
  };

  const { labelsTotal, datasetsTotal, commentsArrayTotal } =
    processDataTotal(totalCountChartData);

  const countingReportOptionsTotal = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: t("report.totalCount"),
        font: {
          size: 14,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return `${t("report.minute")} ${index + 1}`;
          },
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            return `${dataset.label}: ${dataset.data[index]}`;
          },
          footer: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const minuteComments = (commentsArrayTotal || [])
              .filter((c) => c?.count_minute === index + 1)
              .map((c) => c.comment)
              .join(",");
            if (minuteComments) {
              return [`${t("ponds.comment")}: ${minuteComments}`];
            }
            return [];
          },
        },
        displayColors: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("report.time_minutes"),
        },
        // ticks: {
        //   callback: function (value) {
        //     return value;
        //   },
        // },
      },
      y: {
        title: {
          display: false,
        },
        min: 0,
      },
    },
  };

  // Example data
  const barChartData = {
    labels: Array.from({ length: barchartData?.length }, (_, i) =>
      ((i + 1) * minuteInGraph).toString()
    ), // Labels from 1 to 50
    datasets: [
      {
        label: t("report.throughput_per_min"),
        data: barchartData,
        backgroundColor: "rgb(238, 124, 67, 0.6)",
        borderColor: "rgb(238, 124, 67)",
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return `${t("report.minute")} ${index + 1}`;
          },
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            return `${dataset.label}: ${dataset.data[index]}`;
          },
          footer: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const minuteComments = Array.isArray(comments)
              ? comments
                  .filter((comment) => comment.count_minute === index + 1)
                  .map((comment) => comment.comment)
                  .join(", ")
              : "";
            if (minuteComments) {
              return [`${t("ponds.comment")}: ${minuteComments}`];
            }
            return [];
          },
        },
        displayColors: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("report.time_minutes"),
        },
        // ticks: {
        //   callback: function (value) {
        //     return value;
        //   },
        // },
      },
      y: {
        title: {
          display: true,
          text: t("report.total_count_per_chamber"),
        },
      },
    },

    datasets: {
      bar: {
        // barThickness: 20, // Fixed bar width
        maxBarThickness: 25, // Maximum bar width
      },
    },
  };

  const toggleFullScreen = (chartRef) => {
    if (chartRef.current) {
      if (!document.fullscreenElement) {
        chartRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  const correctDataRapport = (oldData) => {
    setLoading(true);
    try {
      // Validate oldData structure
      if (!oldData || typeof oldData !== "object") {
        throw new Error("Invalid input: oldData must be an object.");
      }

      // Extract counter keys and validate them
      const counterKeys = Object.keys(oldData).filter((key) =>
        key?.startsWith("counter")
      );
      if (counterKeys.length === 0) {
        throw new Error("No counters found in the data.");
      }

      // Parse counters and handle parsing errors
      const counters = counterKeys.map((key) => {
        try {
          return JSON.parse(oldData[key]);
        } catch (error) {
          throw new Error(
            `Failed to parse counter data for ${key}: ${error.message}`
          );
        }
      });

      // Check if counters are arrays and have numeric values
      counters.forEach((counter, index) => {
        if (!Array.isArray(counter)) {
          throw new Error(
            `Counter data for ${counterKeys[index]} is not an array.`
          );
        }
        counter.forEach((value) => {
          if (typeof value !== "number") {
            throw new Error(
              `Non-numeric value found in ${counterKeys[index]} array.`
            );
          }
        });
      });

      // Prepare an array to hold new data
      let newData = [];

      // Calculate the total number of minutes
      const maxMinutes = Math.max(...counters.map((counter) => counter.length));

      // Iterate over each minute
      for (let min = 0; min < maxMinutes; min++) {
        // Calculate totalCount for this minute
        const throughput = counters.reduce(
          (acc, counter) => acc + (counter[min] || 0),
          0
        );
        // Totalcount is the sum of throughput       
        const previousTotalCount = min > 0 ? newData[min - 1].totalCount : 0;
        const totalCount = throughput + previousTotalCount;
        
        const comments = oldData.comments || [];
        const minuteComments = (comments || [])
          .filter((c) => c.count_minute === min)
          .map((c) => c.comment)
          .join(",");

        // Add data to the newData array
        newData.push({
          min: min,
          totalCount,
          throughput,
          notes: minuteComments,
        });
      }

      setTotalCorrectRapport(newData.slice(1));
      setLoading(false);
      return newData;
    } catch (error) {
      setLoading(false);
      console.error("Error processing data:", error.message);
      return []; // Return an empty array in case of errors
    }
  };

  const downloadCSV = (data, filename) => {
    const filteredData = data.map((item) => {
      return {
        Minute: item.min,
        "Total Count": item.totalCount,
        Throughput: item.throughput,
        Notes: item.notes,
      };
    });

    // Convert filtered data to worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Convert worksheet to CSV format
    const csv = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob object for the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || "countingReport.csv";
    link.click();
  };

  const downloadExcel = (data, filename) => {
    // Map data to worksheet format
    const filteredData = data.map((item) => ({
      Minute: item.min,
      "Total Count": item.totalCount,
      Throughput: item.throughput,
      Notes: item.notes,
    }));

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    // Generate Excel file and create a Blob
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || "countingReport.xlsx";
    link.click();
  };

  // Helper function to convert binary string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const transformComments = (comments) => {
    return comments.replace(/, ?/g, ",\n");
  };

  const groupedComments = commentList.reduce((acc, comment) => {
    const minute = comment.count_minute;
    if (!acc[minute]) {
      acc[minute] = [];
    }
    acc[minute].push(comment.comment);
    return acc;
  }, {});

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("report.report_pond")}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation("/countingReports", {
          state: {
            pondData: window.history.state.usr.pondData,
            cycleData: window.history.state.usr.cycleData,
          },
        });
      }}
    >
      <div className="report_main_wrapper_page">
        {/* <div className="reports_download">
          <button
            className="ui primary button"
            onClick={() => {
              // const imgData = canvas.toDataURL("image/png");
              var pdf = new jsPDF("l", "mm", "a4");
              var element = document.getElementById("print");
              var opt = {
                pagebreak: { mode: "avoid-all", before: `#id_page_break` },
                margin: [5, 5, 5, 5], // top, left, bottom, right
                filename: `Report.pdf`,
                // html2canvas:  { scale: 1 }
                html2canvas: { scale: 4 },
                jsPDF: { format: "A3", orientation: "landscape" },
              };
              html2pdf().set(opt).from(element).save();

              // html2canvas(document.querySelector("#print"), {
              //   // width: 1080,
              //   // scale: 1
              //   // canvas: canvas,
              // }).then((canvas) => {
              //   // const imgData = canvas.toDataURL('image/png');
              //   // const pdf = new jsPDF();
              //   // var margin = 3;
              //   // var imgWidth = 213 - 3 * margin;
              //   // var imgHeight = canvas.height * imgWidth / canvas.width;
              //   // pdf.addImage(imgData, 'JPEG', margin, 3, imgWidth, imgHeight);
              //   const imgData = canvas.toDataURL("image/png");
              //   // const pdf = new jsPDF();
              //   var pdf = new jsPDF("l", "mm", "a4");

              //   var width = pdf.internal.pageSize.getWidth();
              //   var height = pdf.internal.pageSize.getHeight();

              //   pdf.addImage(imgData, "PNG", 3, 3, width - 6, height - 4);

              //   // pdf.output('dataurlnewwindow');
              //   pdf.save("Report.pdf");
              // });
            }}
          >
            {t("licenseDetails.download")}
          </button>
        </div> */}
        <div className="reports_main_wrapper">
          <div id="print">
            <div className="report_main_wrapper">
              <div className="report_pond_wrap">
                <div className="text_sam_block counting_report_main">
                  <div
                    className="row"
                    style={{ alignItems: "center", marginBottom: 25 }}
                  >
                    <div style={{ width: "33.33%" }}>
                      <div
                        style={{
                          width: 150,
                          height: 100,
                        }}
                      >
                        <img
                          src={imagePath.logo3}
                          alt="logo"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                    <h3 style={{ width: "33.33%", textAlign: "center" }}>
                      {t("ponds.counting_report")}
                    </h3>
                    <h4 style={{ width: "33.33%" }}></h4>
                  </div>
                  <div className="description_content">
                    <div className="summary_row_block">
                      <ul>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.organization"
                          )}:`}</p>
                          <span
                            style={{ marginLeft: "18px" }}
                            className="description_sam_text"
                          >
                            {organization}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.countedBy"
                          )}:`}</p>
                          <span className="description_sam_text">
                            {recordData.reportedUser}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.date"
                          )}: `}</p>
                          <span className="description_sam_text">
                            {getTimestampWithFormatDate(
                              recordData.uploadedOn,
                              "DD-MM-YYYY @ hh:mma"
                            )}
                          </span>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.fromPond"
                          )}:`}</p>
                          <span className="description_sam_text">
                            {recordData.fromPondName}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.toPond"
                          )}:`}</p>
                          <span className="description_sam_text">
                            {recordData.toPondName}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.deviceType"
                          )}:`}</p>
                          <span className="description_sam_text">
                            {recordData.device}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">{`${t(
                            "report.duration"
                          )}:`}</p>
                          <span className="description_sam_text">
                            {totalDuration} min
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        borderTop: "2px solid #eee",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                    <ul>
                      <li>
                        <p className="description_sam_text">{`${t(
                          "report.totalCount"
                        )}:`}</p>
                        <span className="description_sam_text">
                          {recordData.count.toLocaleString()}
                        </span>
                      </li>
                      <li>
                        <p className="description_sam_text">{`${t(
                          "report.totalAveragePer5Min"
                        )}:`}</p>
                        <span className="description_sam_text">
                          {recordData && totalDuration > 0
                            ? (recordData.count / totalDuration).toFixed(1)
                            : "N/A"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="growth_chart counting_report_growth_row">
                    <div className="chart_col">
                      <div
                        className="chart_block counting_report_chart"
                        ref={chartRef1}
                      >
                        <div
                          onClick={() => toggleFullScreen(chartRef1)}
                          className="full_screen"
                        >
                          <img src={fullscreen} alt="image" />
                        </div>
                        <Line
                          data={{
                            labels: labelsTotal,
                            datasets: datasetsTotal,
                          }}
                          options={countingReportOptionsTotal}
                        />
                      </div>
                    </div>
                    {commentList.length > 0 ? (
                      <div className="chart_col">
                        <div
                          className="chart_block counting_report_chart"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="inventory_table_wrap"
                            style={{ padding: 0 }}
                          >
                            <div className="table-responsive cm_table_responsive">
                              <table className="cm_table fold-table">
                                <thead className="cm_thead">
                                  <tr className="cm_thead_list">
                                    <th className="th_list cm_name">
                                      <div className="th_item_wrap">
                                        <h6>{t("report.minute")}</h6>
                                      </div>
                                    </th>
                                    <th className="th_list cm_name">
                                      <div className="th_item_wrap">
                                        <h6>{t("report.notes")}</h6>
                                      </div>
                                    </th>
                                    {/* <th className="th_list cm_name"></th> */}
                                  </tr>
                                </thead>
                                <tbody
                                  className="cm_tbody"
                                  style={{ paddingLeft: 14, maxHeight: 300 }}
                                >
                                  {Object.entries(groupedComments).map(
                                    ([minute, comments], index) => (
                                      <>
                                        <tr className="cm_tr view" key={index}>
                                          <td className="cm_td">{minute}</td>
                                          <td
                                            className="cm_td"
                                            style={{ justifyContent: "center" }}
                                          >
                                            {comments?.map((comment, idx) => (
                                              <React.Fragment key={idx}>
                                                {comment}
                                                {idx < comments.length - 1 &&
                                                  ", "}
                                                {idx < comments.length - 1 && (
                                                  <br />
                                                )}
                                              </React.Fragment>
                                            ))}
                                          </td>
                                        </tr>
                                        <tr className="cm_hi"></tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {commentList.length > 0 && (
                      <div className="chart_col">
                        <div
                          className="chart_block counting_report_chart"
                          style={{ height: "100%" }}
                        >
                          <table style={tableStyle}>
                            <thead
                              style={{
                                display: "table",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  tableLayout: "fixed",
                                  overflow: "hidden",
                                  width: "100%",
                                  display: "table",
                                  height: "fit-content",
                                }}
                              >
                                <th style={{ textAlign: "center" }}>
                                  {t("report.minute")}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {t("report.note")}
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                height: 350,
                                overflowY: "auto",
                                display: "block",
                                width: "100%",
                              }}
                            >
                              {Object.entries(groupedComments).map(
                                ([minute, comments], index) => (
                                  <tr
                                    key={index}
                                    style={
                                      index % 2 === 0
                                        ? evenRowStyle
                                        : oddRowStyle
                                    }
                                  >
                                    <td style={tdStyle}>{minute}</td>
                                    <td style={tdStyle}>
                                      {comments?.map((comment, idx) => (
                                        <React.Fragment key={idx}>
                                          {comment},
                                          {idx < comments.length - 1 && <br />}
                                        </React.Fragment>
                                      ))}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="growth_chart counting_report_growth_row">
                    <div className="chart_col">
                      <div
                        className="chart_block counting_report_chart"
                        ref={chartRef2}
                      >
                        <div
                          onClick={() => toggleFullScreen(chartRef2)}
                          className="full_screen"
                        >
                          <img src={fullscreen} alt="image" />
                        </div>
                        <Bar data={barChartData} options={barChartOptions} />
                      </div>
                    </div>
                    <div className="chart_col">
                      <div
                        className="chart_block counting_report_chart"
                        ref={chartRef3}
                      >
                        <div
                          onClick={() => toggleFullScreen(chartRef3)}
                          className="full_screen"
                        >
                          <img src={fullscreen} alt="image" />
                        </div>
                        <Line
                          data={{ labels, datasets }}
                          options={countingReportOptions}
                        />
                      </div>
                    </div>
                  </div>
                  {/* {options &&
                    options.length > 0 &&
                    lines &&
                    lines.length > 0 && (
                      <>
                        <div className="growth_chart counting_report_growth_row">
                          {options.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="chart_col"
                                  id={`${(index + 1) % (index === 0 ? 2 : 4) === 0
                                      ? "id_page_break"
                                      : ""
                                    }`}
                                >
                                  <div className="chart_block counting_report_chart">
                                    <Line
                                      data={lines[index]}
                                      options={item}
                                      id="chart"
                                    ></Line>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )} */}
                  {totalCorrectRapport?.length > 0 && (
                    <div style={{ marginTop: 20 }}>
                      {totalCorrectRapport.length !== 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginBottom: 15,
                          }}
                        >
                          <div
                            className="head_two select-main-block"
                            style={{ marginRight: 15 }}
                          >
                            <Box sx={{ minWidth: 260 }}>
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={exportType}
                                  label="Age"
                                  onChange={async (e) => {
                                    e.stopPropagation();
                                    try {
                                      setExportType(e.target.value);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                  input={<BootstrapInput />}
                                >
                                  {exportTypeList.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <div
                            style={{
                              height: 40,
                            }}
                          >
                            <button
                              className="ui primary button"
                              onClick={() => {
                                if (exportType === "excel") {
                                  downloadExcel(
                                    totalCorrectRapport,
                                    "countingReport.xlsx"
                                  );
                                } else {
                                  downloadCSV(
                                    totalCorrectRapport,
                                    "countingReport.csv"
                                  );
                                }
                              }}
                            >
                              {t("licenseDetails.download")}
                            </button>
                          </div>
                        </div>
                      )}
                      <div>
                        <div>
                          <div className="inventory_table_wrap">
                            <div className="table-responsive cm_table_responsive">
                              {isLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div id={`load`} className="loader" />
                                </div>
                              ) : totalCorrectRapport.length > 0 ? (
                                <table className="cm_table fold-table">
                                  <thead className="cm_thead">
                                    <tr className="cm_thead_list">
                                      <th className="th_list cm_name">
                                        <div className="th_item_wrap">
                                          <h6>{t("report.minute")}</h6>
                                        </div>
                                      </th>
                                      <th className="th_list cm_name">
                                        <div className="th_item_wrap">
                                          <h6> {t("report.total_count")}</h6>
                                        </div>
                                      </th>
                                      <th className="th_list cm_name">
                                        <div className="th_item_wrap">
                                          <h6>{t("report.throughput")}</h6>
                                        </div>
                                      </th>{" "}
                                      <th className="th_list cm_name">
                                        <div className="th_item_wrap">
                                          <h6>{t("report.notes")}</h6>
                                        </div>
                                      </th>
                                      {/* <th className="th_list cm_name"></th> */}
                                    </tr>
                                  </thead>
                                  <tbody
                                    className="cm_tbody"
                                    style={{ height: 330 }}
                                  >
                                    {totalCorrectRapport.map(
                                      (report, index) => (
                                        <>
                                          <tr
                                            key={report.id}
                                            className="cm_tr view"
                                          >
                                            <td className="cm_td">
                                              {report?.min.toLocaleString()}
                                            </td>
                                            <td className="cm_td">
                                              {device_version ===
                                              device_version_3
                                                ? sumUntilIndex(
                                                    totalCorrectRapport.map(
                                                      (val) =>
                                                        parseInt(val.totalCount)
                                                    ),
                                                    index
                                                  )
                                                : report?.totalCount.toLocaleString()}
                                            </td>
                                            <td className="cm_td">
                                              {device_version ===
                                              device_version_3
                                                ? report?.totalCount.toLocaleString()
                                                : report?.throughput.toLocaleString()}
                                            </td>
                                            <td
                                              className="cm_td"
                                              style={{ display: "block" }}
                                            >
                                              {report?.notes
                                                ? transformComments(
                                                    report.notes
                                                  )
                                                : "-"}
                                            </td>
                                          </tr>
                                          <tr className="cm_hi"></tr>
                                        </>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: 17, fontWeight: "600" }}
                                  >
                                    {t("device.device_not_found")}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveDrawer>
  );
};

export default CountingReport;
