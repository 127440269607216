import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from './config/firebase-config';

export default function AuthProtectedRoute(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); 

   
    useEffect(() => {
        // auth.signOut()
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            // console.log(user , "user")
            if(user){
                navigate("/home");
            } 
            setLoading(false);
            // if (user && user.uid && user?.emailVerified === true && (location.pathname === "/login" ||  location.pathname === "/signup" || location.pathname === "/forgot_password")) {  
            // } 
        });
        return () => unsubscribe();
    }, [navigate]);

    if (loading) {
        return (
            <div className="four_zero_four_image_container" style={{ marginTop: 15 }}>
                <div id="load" className="loader" />
            </div>
        );
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}